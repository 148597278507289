import { Fetch } from "@/utils";

//下单接口
const postCouponOrder = (params: any) =>
  Fetch(`/coupon/order`, {
    method: "POST", 
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: params 
  });

//订单详情接口
const getOrderDetail = (params: any) =>
  Fetch(`/coupon/orderDetail?batch_no=${params.batch_no}`, { method: "GET" });

//用户订单列表接口
const getUserOrders = (params: any) =>
  Fetch(`/coupon/userOrders?page=${params.page}`, { method: "GET" });

//优惠列表-头部品牌
const getBrandList = () => Fetch(`/coupon/brandList`, { method: "GET" });

// 优惠列表-优惠券产品
const getGoodsList = (params: any) =>
  Fetch(`/coupon/goodsList?brand_id=${params.id}&page=${params.page}`, {
    method: "GET"
  });

// 优惠券详情接口
const getGoodsDetail = (params: any) =>
  Fetch(`/coupon/goodsDetail?id=${params.id}`, { method: "GET" });

// checkPay
const checkPay = (params: any) =>
  Fetch(`/coupon/checkPay?batch_no=${params.batch_no}`, { method: "GET" });

//用户口罩退款
const refund = (params: any) =>
Fetch(`/coupon/order/refund`, { method: "POST", headers: {
  'Content-Type': 'application/json'
},
data: params });

export default {
  getGoodsList,
  checkPay,
  postCouponOrder,
  getGoodsDetail,
  getBrandList,
  getUserOrders,
  getOrderDetail,
  refund
};
