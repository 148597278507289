import Service from "../service";
import { Tools } from "@/utils";

const carOwner = {
  state: {
    oilInfo: "",
    oilNameArray: "",
    initGunNos: "",
    gunNos: "",
    gunNo: ""
  },
  reducers: {
    oilInfo(state: object, data?: object) {
      return {
        ...state,
        oilInfo: data
      };
    },
    oilNameArray(state: object, data?: object) {
      return {
        ...state,
        oilNameArray: data
      };
    },
    initGunNos(state: object, data?: object) {
      return {
        ...state,
        initGunNos: data
      };
    },
    gunNos(state: object, data?: object) {
      return {
        ...state,
        gunNos: data
      };
    },
    gunNo(state: any, data?: object) {
      return {
        ...state,
        gunNo: data
      };
    }
  },
  effects: () => ({
    /**
     * @description 页面初始化
     * @param {object} payload
     * @param {object} rootState
     */
    async getOilInfo(payload: any, rootState?: object) {
      const { data } = await Service.getOilInfo(payload);
      if (data.length === 0) {
        (this as any).oilNameArray([]);
        return false;
      }
      // 油号数据 选中状态 切换为false
      const oilNameArray = data.list.map((item: any) => ({
        oilName: item.oil_name,
        active: false
      }));

      let index: number = 0;
      if (!!payload.oliName) {
        for (let i = 0; i < oilNameArray.length; i++) {
          if (oilNameArray[i].oilName === payload.oliName) {
            index = i;
          }
        }
      }
      // console.log(oilNameArray,'oilNameArray')
      // 索引为 对应油号的 index
      oilNameArray[index].active = true;
      (this as any).oilNameArray(oilNameArray);

      // 油号[0]数据 对应的 油枪数据 选中状态 切换为false
      const gunNosArray = Tools.getCurrentPart(
        // 索引为 对应油号的 index
        oilNameArray[index].oilName,
        data.list
      ).map((item: any) => ({
        gunNo: item.gunNo,
        active: false
      }));
      // gunNosArray[0].active = true;
      (this as any).initGunNos(gunNosArray);
      // (this as any).gunNo(gunNosArray[0].gunNo);

      // 所有油枪的数据
      const gunNosArrayAll = data.list;
      (this as any).gunNos(gunNosArrayAll);

      // 油站数据
      const oilInfo = {
        station_name: data.station_name,
        station_pic: data.station_pic,
        location: data.location
      };
      (this as any).oilInfo(oilInfo);
    },

    /**
     * @description 油号变更
     * @param {*} payload
     * @param {object} rootState
     */
    async onChangeOilName(payload: any, rootState?: object) {
      // 油号数据变更 选中状态 切换为false
      const oilNameArray = (rootState as any).carOwner.oilNameArray.map(
        (item: any) => ({
          oilName: item.oilName,
          active: false
        })
      );
      oilNameArray[payload.index].active = true;
      (this as any).oilNameArray(oilNameArray);

      // 油号数据变更 选中状态 切换为false
      const gunNosArray = Tools.getCurrentPart(
        oilNameArray[payload.index].oilName,
        (rootState as any).carOwner.gunNos
      ).map((item: any) => ({
        gunNo: item.gunNo,
        active: false
      }));
      // gunNosArray[0].active = true;
      (this as any).initGunNos(gunNosArray);
      (this as any).gunNo("");
    },
    /**
     * @description 油枪变更
     * @param {*} payload
     * @param {object} rootState
     */
    async onChangeGunNos(payload: any, rootState?: object) {
      // 油枪数据变更 选中状态 切换为false
      const gunNosArray = (rootState as any).carOwner.initGunNos.map(
        (item: any) => ({
          gunNo: item.gunNo,
          active: false
        })
      );
      gunNosArray[payload.index].active = true;
      (this as any).initGunNos(gunNosArray);
      (this as any).gunNo(payload.name);
    }
  })
};

export default carOwner;
