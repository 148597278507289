import { Fetch } from "@/utils";
type typeOptions = {
  [key: number]: any
}
//状态1未开奖 2中奖 3未中奖
const codeStatus: typeOptions = {
  1: {
    type: 'wating',
    msg: '待分配'
  },
  2: {
    type: 'get',
    msg: '分配成功'
  },
  3: {
    type: 'miss',
    msg: '分配失败'
  }
}
const activityInvite = {
  state: {
    loading: true,
    source: {},
    types: [{
      type: 'people',
      text: '累计邀请好友',
      key: 'peopleList'
    }, {
      type: 'shareCode',
      text: '好友参与码明细',
      key: 'shareCodeList'
    }, {
      type: 'shareWin',
      text: '成功分配',
      key: 'shareWinList'
    }],
    typeIndex: 0,
    list: [],
  },
  reducers: {
    set(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
    setType(state: any, index: string) {
      const { types, source } = state;
      const { key } = types[index];
      return {
        ...state,
        typeIndex: index,
        list: source[key]
      }
    },
  },
  effects: (dispatch: any) => {
    return {
      async init(query: any) {
        const res = await Fetch(`/activity/draw/shareData`);
        // 正常流程，不写else ，是为了放出登录验证
        if (res && res.code === 1 && res.data) {

          const data = res.data;
          data.shareCodeList = data.shareCodeList.map((item: any, index: number) => {
            return {
              ...item,
              ...codeStatus[item.state]
            }
          });

          try {
            dispatch.activityInvite.set({
              source: {
                ...data
              },
              list: data.peopleList
            });
          } catch (err) {
            console.log('err', err)
          }
        } else {
          console.log('err', res)
        }
      },
    }
  }
};


export default activityInvite;
