/**
 * 客户端环境识别模块
 */

import { isFunction, isString, isRegExp, isObject } from "./typeof";

const win = window;
let UA: string = enhanceUA();

function enhanceUA(): string {
  const navigator: Navigator = win.navigator;
  const userAgent: string = navigator.userAgent || "";
  const appVersion: string = navigator.appVersion || "";
  const vendor: string = navigator.vendor || "";
  const UA: string = `${userAgent} ${appVersion} ${vendor}`.toLowerCase();
  return UA;
}

const NA_VERSION = "-1";

const NA = {
  name: "na",
  version: NA_VERSION
};

const REG_DEVICES = [
  ["ipad", "ipad"],
  // ipod 规则应置于 iphone 之前。
  ["ipod", "ipod"],
  ["iphone", /\biphone\b|\biph(\d)/],
  [
    "samsung",
    function (ua: any) {
      if (ua.indexOf("samsung") !== -1) {
        return /\bsamsung(?:[-](?:sgh|gt|sm))?-([a-z0-9]+)/;
      } else {
        return /\b(?:sgh|sch|gt|sm)-([a-z0-9]+)/;
      }
    }
  ],
  ["android", /\bandroid\b|\badr\b/],
  // 小米
  ["mi", /\bmi[ -]?([a-z0-9 ]+(?= build|\)))/],
  // 红米
  ["hongmi", /\bhm[ -]?([a-z0-9]+)/],
  [
    "meizu",
    function (ua: any) {
      return ua.indexOf("meizu") >= 0
        ? /\bmeizu[/ ]([a-z0-9]+)\b/
        : /\bm([0-9cx]{1,4})\b/;
    }
  ],
  ["nexus", /\bnexus ([0-9s.]+)/],
  [
    "huawei",
    function (ua: any) {
      const RE_MEDIAPAD = /\bmediapad (.+?)(?= build\/huaweimediapad\b)/;
      if (ua.indexOf("huawei-huawei") !== -1) {
        return /\bhuawei-huawei-([a-z0-9-]+)/;
      } else if (RE_MEDIAPAD.test(ua)) {
        return RE_MEDIAPAD;
      } else {
        return /\bhuawei[ _-]?([a-z0-9]+)/;
      }
    }
  ],
  ["oppo", /\boppo[_ ]([a-z0-9]+)/]
];

const REG_BROWSER = [
  ["tantutravel", /\/com\.tantu\.travel\/([0-9.]+)/],
  ["tantumap", /\/com\.tantu\.map(?:[\w\d.]+|)\/([0-9.]+)/],
  ["zuzuche", /zzc(?:ios|android)\/([0-9.]+)/],
  ["zuzucherewards", /zzc(?:ios|android)\/rewards\/([0-9.]+)/],
  ["drivingtravel", /tantu(?:ios|android)\/cartour\/([0-9.]+)/],
  ["black", /black/],
  [
    "weixin",
    function (ua: any) {
      return (
        /\bmicromessenger\/([\d.]+)/.test(ua) && ua.indexOf("wxwork/") === -1
      );
    }
  ],
  // 企业微信
  [
    "weixinwork",
    function (ua: any) {
      return (
        /\bmicromessenger\/([\d.]+)/.test(ua) && ua.indexOf("wxwork/") !== -1
      );
    }
  ],
  ["qq", /\bm?qqbrowser\/([0-9.]+)/],
  // UC 浏览器，可能会被识别为 Android 浏览器，规则需要前置。
  // UC 桌面版浏览器携带 Chrome 信息，需要放在 Chrome 之前。
  [
    "uc",
    function (ua: any) {
      if (ua.indexOf("ucbrowser/") >= 0) {
        return /\bucbrowser\/([0-9.]+)/;
      } else if (ua.indexOf("ubrowser/") >= 0) {
        return /\bubrowser\/([0-9.]+)/;
      } else if (/\buc\/[0-9]/.test(ua)) {
        return /\buc\/([0-9.]+)/;
      } else if (ua.indexOf("ucweb") >= 0) {
        // `ucweb/2.0` is compony info.
        // `UCWEB8.7.2.214/145/800` is browser info.
        return /\bucweb([0-9.]+)?/;
      } else {
        return /\b(?:ucbrowser|uc)\b/;
      }
    }
  ],
  ["weibo", /weibo/],
  ["oppobrowser", /\boppobrowser\/([0-9.]+)/],
  ["chrome", / (?:chrome|crios|crmo)\/([0-9.]+)/],
  // Android 默认浏览器。该规则需要在 safari 之前。
  [
    "android",
    function (ua: any) {
      if (ua.indexOf("android") === -1) {
        return;
      }
      return /\bversion\/([0-9.]+(?: beta)?)/;
    }
  ],
  [
    "safari",
    /\bversion\/([0-9.]+(?: beta)?)(?: mobile(?:\/[a-z0-9]+)?)? safari\//
  ],
  // 如果不能被识别为 Safari，则猜测是 WebView。
  ["webview", /\bcpu(?: iphone)? os (?:[0-9._]+).+\bapplewebkit\b/],
  ["firefox", /\bfirefox\/([0-9.ab]+)/]
];

const REG_ENGINE = [
  [
    "blink",
    function () {
      return "chrome" in win && "CSS" in win && /\bapplewebkit[/]?([0-9.+]+)/;
    }
  ],
  ["webkit", /\bapplewebkit[/]?([0-9.+]+)/],
  ["u2", /\bu2\/([0-9.]+)/],
  ["u3", /\bu3\/([0-9.]+)/]
];

// 操作系统信息识别表达式
const REG_OS = [
  [
    "ios",
    function (ua: any) {
      if (/\bcpu(?: iphone)? os /.test(ua)) {
        return /\bcpu(?: iphone)? os ([0-9._]+)/;
      } else if (ua.indexOf("iph os ") !== -1) {
        return /\biph os ([0-9_]+)/;
      } else {
        return /\bios\b/;
      }
    }
  ],
  [
    "android",
    function (ua: any) {
      if (ua.indexOf("android") >= 0) {
        return /\bandroid[ /-]?([0-9.x]+)?/;
      } else if (ua.indexOf("adr") >= 0) {
        if (ua.indexOf("mqqbrowser") >= 0) {
          return /\badr[ ]\(linux; u; ([0-9.]+)?/;
        } else {
          return /\badr(?:[ ]([0-9.]+))?/;
        }
      }
      return "android";
    }
  ]
];

// UserAgent Detector.
// @param {String} ua, userAgent.
// @param {Object} expression
// @return {Object}
//    返回 null 表示当前表达式未匹配成功。
function detect(name: any, expression: any) {
  const expr = isFunction(expression) ? expression.call(null, UA) : expression;
  if (!expr) {
    return null;
  }
  const info = {
    name: name,
    version: NA_VERSION,
    codename: ""
  };
  if (expr === true) {
    return info;
  } else if (isString(expr)) {
    if (UA.indexOf(expr) !== -1) {
      return info;
    }
  } else if (isObject(expr)) {
    if (expr.hasOwnProperty("version")) {
      info.version = expr.version;
    }
    return info;
  } else if (isRegExp(expr)) {
    const m = expr.exec(UA);
    if (m) {
      if (m.length >= 2 && m[1]) {
        info.version = m[1].replace(/_/g, ".");
      } else {
        info.version = NA_VERSION;
      }
      return info;
    }
  }
}

function each(object: any, factory: any) {
  for (let i = 0, l = object.length; i < l; i++) {
    if (factory.call(object, object[i], i) === false) {
      break;
    }
  }
}

function parse() {
  UA = enhanceUA();

  let Detector = {};

  (Detector as any).device = (function () {
    let result: any = NA;
    each(REG_DEVICES, function (pattern: any) {
      const d = detect(pattern[0], pattern[1]);
      if (d) {
        const v: number = parseFloat(d.version);
        result = {
          name: d.name,
          version: v,
          fullVersion: d.version
        };
        result[d.name] = true;
        return false;
      }
    });
    return result;
  })();
  (Detector as any).os = (function () {
    let result: any = NA;
    each(REG_OS, function (pattern: any) {
      const d: any = detect(pattern[0], pattern[1]);
      if (d) {
        const v = parseFloat(d.version);
        result = {
          name: d.name,
          version: v,
          fullVersion: d.version
        };
        result[d.name] = v;
        return false;
      }
    });
    return result;
  })();
  (Detector as any).browser = (function () {
    let result: any = NA;
    each(REG_BROWSER, function (pattern: any) {
      const d: any = detect(pattern[0], pattern[1]);
      if (d) {
        const v = parseFloat(d.version);
        result = {
          name: d.name,
          version: v,
          fullVersion: d.version
        };
        result[d.name] = v;
        return false;
      }
    });
    return result;
  })();
  (Detector as any).engine = (function () {
    let result: any = NA;
    each(REG_ENGINE, function (pattern: any) {
      const d = detect(pattern[0], pattern[1]);
      if (d) {
        const v = parseFloat(d.version);
        result = {
          name: d.name,
          version: v,
          fullVersion: d.version
        };
        result[d.name] = v;
        return false;
      }
    });
    return result;
  })();

  return Detector;
}

const Detector = parse();

export default Detector;

export { parse };
