/**
 * 简单 localStorage 调用分装
 * 统一前缀 `privilege-`
 * 过期时间统一后缀 `-cacheexpiration`
 * 过期时间为 `number`, 默认单位 `min`
 * 暴露方法：
 * storage.set(key, val, expired); expired 可选，不填则表示不过期 (注：过期设置对直接原生的 localStorage 调用无效)
 * storage.set({key,prefix},val,expired) 可以自定义前缀
 * storage.get(key);
 * storage.has(key);
 * storage.remove(key);
 * storage.supported(); 是否支持 localStorage
 */

const ls = window.localStorage;
const PREFIX = "privilege-";
const EXPIRED_SUFFIX = "-cacheexpiration";
const expiryMilliseconds = 1 * 60 * 1000; // 默认过期时间以 1 min 为单位
let isSupport: any;

function stringify(val: any) {
  // 处理特殊 val
  return val === undefined || typeof val === "function"
    ? "" + val
    : JSON.stringify(val);
}

function warn(error: any) {
  if (!("console" in window) || typeof console.warn !== "function") {
    return false;
  }
  console.warn(error);
}

function lsKey(_key: any) {
  if (_key && typeof _key === "object") {
    let { prefix, key } = _key;
    return prefix ? prefix + key : key;
  }
  return PREFIX + _key;
}

function expirationKey(_key: any) {
  return typeof _key === "object"
    ? { key: _key.key + EXPIRED_SUFFIX, prefix: _key.prefix }
    : _key + EXPIRED_SUFFIX;
}

function currentTime() {
  return new Date().getTime();
}

function getItem(key: any) {
  return ls.getItem(lsKey(key));
}

function setItem(key: any, value: any) {
  ls.removeItem(lsKey(key));
  ls.setItem(lsKey(key), value);
}

function removeItem(key: any) {
  ls.removeItem(lsKey(key));
}

function flushExpiredItem(key: any) {
  let eKey = expirationKey(key);
  let expr = getItem(eKey);

  if (expr) {
    let time = parseInt(expr, 10);
    // 过期则移除该项
    if (currentTime() >= time) {
      removeItem(key);
      removeItem(eKey);
      return true;
    }
  }

  return false;
}

// 是否支持 localStorage
function supportStorage() {
  let key = "__lscachetest__";
  let value = key;

  if (isSupport !== undefined) {
    return isSupport;
  }

  try {
    if (!ls) {
      return false;
    }
  } catch (error) {
    return false;
  }

  try {
    setItem(key, value);
    removeItem(key);
    isSupport = true;
  } catch (error) {
    isSupport = false;
  }

  return isSupport;
}

const storage = {
  // 设置 localStorage, 过期时间 expired 单位 min
  set: function(key: any, val: any, expired?: any) {
    if (!supportStorage()) {
      return false;
    }

    try {
      val = stringify(val);
    } catch (error) {
      warn(error);
      return false;
    }

    try {
      setItem(key, val);
    } catch (error) {
      warn(error);
      return false;
    }

    // 有过期时间
    if (expired) {
      setItem(
        expirationKey(key),
        (currentTime() + expired * expiryMilliseconds).toString()
      );
    } else {
      // 移除之前设置的过期时间
      removeItem(expirationKey(key));
    }

    return true;
  },
  get: function(key: any) {
    if (!supportStorage()) {
      return null;
    }
    // 获取前，检查是否过期，过期则返回 null
    if (flushExpiredItem(key)) {
      return null;
    }

    let val: any = getItem(key);
    try {
      return JSON.parse(val);
    } catch (error) {
      return val;
    }
  },
  has: function(key: any) {
    return !!(this.get(key) !== null);
  },
  remove: function(key: any) {
    if (!supportStorage()) {
      return false;
    }
    removeItem(key);
  },
  // 是否支持 localStorage
  supported: function() {
    return supportStorage();
  }
};

export default storage;
