import { Fetch } from "@/utils";
import { Toast } from "antd-mobile";
type typeOptions = {
  [key: number]:  any
}
const typeMap:typeOptions = {
  1: {
    status: 'handleing',
    statusText: '处理中...',
    btnText: '提醒处理',
    btnClickText: '正在加急处理中，请稍后~'
  },
  2: {
    status: 'waiting',
    statusText: '待充值',
    btnText: '提醒充值',
    btnClickText: '正在加急充值中，请稍后~'
  }, 
  3: {
    status: 'done',
    statusText: '已完成',
    btnText: '申请售后',
  },
  4: {
    status: 'refund' ,
    statusText: '已退款',
    btnText: '已退款',
    btnClickText: '订单已原路退回到您的账户'
  },
  6: {
    status: 'refunding' ,
    statusText: '退款中...',
    btnText: '提醒退款',
    btnClickText: '正在加急退款中，请稍后~'
  }
};

const rechargeOrderDetail = {
  state: {
    loading: true,
    showModal: false,
    account: '',
    order_id: '',
    add_time: '',
    total_price: '',
    goods_img: '',
    sku_name: '',
    type_name: '',
    attributes: '',
    btnText: '',
    btnClickText: '',
    status: ''
  },
  reducers: {
    setOrderDetail(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
    setShowModal(state: any, showModal: boolean) {
      return {
        ...state,
        showModal
      }
    }
  },
  effects: (dispatch: any) => {
    return {
      async init(orderId: string) {
        const { data, code, msg } = await Fetch('/recharge/order',{
          params: { 
            order_id: orderId
          }
        });
        dispatch.rechargeOrderDetail.setOrderDetail({
          ...data,
          ...typeMap[data.order_status]
        });
      },
      // 再次提交订单
      async submitAgain(orderId: string){
        const { data, code, msg } = await Fetch('/recharge/order/urge',{
          method: "POST",
          params: { 
            order_id: orderId
          }
        });
        if(data && code == 1){
          dispatch.rechargeOrderDetail.setOrderDetail({
            ...data,
            ...typeMap[data.order_status]
          });
        }else{
          //Toast.info(msg, 1);
        }
      }
    }
  }
};

export default rechargeOrderDetail;
