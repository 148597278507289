import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  RouteProps
} from "react-router-dom";

const LayoutComponent = lazy(() => import("../components/Layout"));
const Arouse = lazy(() => import("../pages/Arouse"));

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const hashArouse = window.location.hash;
const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props =>
        hashArouse !== "" && hashArouse === "#/arouse" ? (
          <Redirect to={{ pathname: `/arouse` }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const Routers = () => {
  return (
    <Router>
      <Switch>
        <Route path="/arouse" component={Arouse} />
        <PrivateRoute component={LayoutComponent} />
      </Switch>
    </Router>
  );
};

export default Routers;
