import { Fetch } from "@/utils";
import { Toast } from "antd-mobile";
import { backToPreviousPage } from "@/native";

let lastInfo: any = '';
try {
    lastInfo = JSON.parse(localStorage.getItem('last_bill_info') || '');
} catch (error) {
    
}

const petrolBill = {
    state: {
        showPop: false,
        billType: lastInfo.billType || 1,
        billTitle: lastInfo.billTitle || '',
        billMoney: '',
        taxId: lastInfo.taxId || '',
        email: lastInfo.email || '',
        isTaxIdLoading: false,
        taxIdCompanyList: []
    },
    reducers: {
        setShowPop(state: any, data?: any) {
            return {
              ...state,
              showPop: data
            }
        },
        setBillType(state: any, data?: any) {
            return {
                ...state,
                billType: data
            }
        },
        setBillTitle(state: any, data?: any) {
            return {
                ...state,
                billTitle: data
            }
        },
        setBillMoney(state: any, data?: any) {
            return {
                ...state,
                billMoney: data
            }
        },
        setEmail(state: any, data?: any) {
            return {
                ...state,
                email: data
            }
        },
        setTaxId(state: any, data?: any) { 
            return {
                ...state,
                taxId: data
            }
        },
        setIsTaxIdLoading(state: any, data?: any) {
            return {
                ...state,
                isTaxIdLoading: data
            }
        },
        setTaxIdCompanyList(state: any, data?: any) {
            return {
                ...state,
                taxIdCompanyList: data
            }
        }
    },
    effects: (dispatch: any) => ({
        async applyPetrolBill(payload: any) {
            Toast.loading('', 0);
            const { data, code, msg } = await Fetch(
                `/station/applyInvoice`,
                { 
                    method: "POST",
                    // headers: {
                    //     'Content-Type': 'application/x-www-form-urlencoded'
                    // },
                    data: payload,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            // console.log(data)
            Toast.hide();
            if(code === 1 && data) {
                dispatch.petrolBill.setShowPop(false);
                Toast.info('提交成功，请稍后注意查收邮件');
                setTimeout(() => {
                    // backToPreviousPage();
                    window.location.href = '/petrolBillHistory';
                }, 2000);
            } else {
                Toast.info(msg || '提交失败，请稍后重试');
            }
        },
        async calculateAmount(payload: any, rootState?: any) {
            Toast.loading('', 0);
            const { data, code , msg } = await Fetch(
                `/station/calculateAmount?ids=${payload.ids}`,
                { method: "GET"}
            );
            Toast.hide();
            if(code === 1 && data) {
                dispatch.petrolBill.setBillMoney(data.amount);
            }
        },
        async getCompanyTaxId(payload: any, rootState?: any) {
            // Toast.loading('', 0);
            dispatch.petrolBill.setIsTaxIdLoading(true);
            const { data, code , msg } = await Fetch(
                `/station/searchTaxNo?companyName=${payload.name}`,
                { method: "GET"}
            );
            Toast.hide();
            dispatch.petrolBill.setIsTaxIdLoading(false);
            if(code === 1 && data) {
                dispatch.petrolBill.setTaxIdCompanyList(data);
            }
        }
    })
};

export default petrolBill;