import { Fetch } from "@/utils";
import { Toast } from "antd-mobile";
const rechargeOrders = {
  state: {
    loading: true,
    list: [],
    selectOrderId: '',
    confirmDelete: false,
    moreStatus: 'init',
    loadingText: '',
    page: 1
  },
  reducers: {
    setOrders(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
    setConfirmDelete(state: any, confirmDelete: boolean) {
      return {
        ...state,
        confirmDelete
      }
    },
    setOrderId(state: any, id: any) {
      return {
        ...state,
        selectOrderId: id
      }
    },
    updateOrders(state: any, orderId: any){
      const newOrderList = state.list.filter((order:object) => {
        return  (order as any).order_id !== orderId
      });
      return{
        ...state,
        list: newOrderList,
        confirmDelete: false
      }
    },
    setMoreStatus(state: any, moreStatus: any){
      return{
        ...state,
        ...moreStatus
      }
    }
  },
  effects: (dispatch: any) => {
    return {
      async getOrders(type = 'init', rootState?: any) {
        const { rechargeOrders } = rootState;
        if(rechargeOrders.moreStatus === 'end' || rechargeOrders.moreStatus === 'loading'){
          return;
        }

        dispatch.rechargeOrders.setMoreStatus({
          moreStatus: 'loading',
          loadingText: '正在加载中...'
        });
        const { data, code } = await Fetch(`/recharge/orderList`, {
          params: {
            page: rechargeOrders.page
          }
        });
        if(data && code == 1){
          const moreStatus = data.page_count > rechargeOrders.page ? 'more' : 'end';
          if(type === 'add'){
            data.list = [...rechargeOrders.list, ...data.list];
          }
          try {
            dispatch.rechargeOrders.setOrders({
              ...data, 
              moreStatus, 
              loadingText: moreStatus == 'more' ? '加载更多' : '没有更多了',
              page: moreStatus == 'more' ? rechargeOrders.page + 1 : rechargeOrders.page
            });
          } catch (err) {
            dispatch.rechargeOrders.setOrders({});
          }
        }else{
          dispatch.rechargeOrders.setOrders({});
        }
      },
      // 删除订单
      async deleteOrder(orderId: any, rootState:any) {
        const { data, code, msg} = await Fetch(`/recharge/order`, {
          method: "DELETE",
          params: {
            order_id: orderId
          }
        });
        if(data && code == 1){
          dispatch.rechargeOrders.updateOrders(orderId);
        }else{
          Toast.info(msg);
        }
      }
    }
  }
};

export default rechargeOrders;
