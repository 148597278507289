import Service from "../service";

const brand = {
  state: {
    productInfo: ""
  },
  reducers: {
    productInfo(state: any, data?: object) {
      return {
        ...state,
        productInfo: data
      }
    }
  },
  effects: () => ({
    async getProductInfo(payload: object) {
      const { data } = await Service.getProductInfo(payload);
      try {
        if(data.length === 0){
          (this as any).productInfo("")
        }else{
          (this as any).productInfo(data)
        }
      }
      catch (err) {
        (this as any).productInfo("")
      }
    }
  })
};

export default brand;
