interface configType {
    readonly shareTitle: string;
    readonly shareContent: string;
    readonly shareIcon: string;
    readonly dozzc: string;
    readonly redDomain: string;
    readonly domain: string;
    readonly is_pro: boolean;
    readonly api: string;
    readonly wechat_account_type: number;
}

const isPro = process.env.REACT_APP_PATH_CONFIG === 'release';
const isLocal = process.env.REACT_APP_PATH_CONFIG === 'dev';
const isRel = false;
const localDomain = window.location.host;

// https://s-uid.zuzuche.net/api/
const config: configType = {
    is_pro: isPro,
    domain: isPro ? '//s.zuzuche.com' : `//s-uid.zuzuche.net`,
    dozzc: (isPro || isRel) ? '//m.zuzuche.com' : '//m.zuzuche.net',
    api: isPro ? '//s-api.zuzuche.com/api/' : 'https://s-api-dev.uid-devx.zuzuche.net/api/',
    redDomain: isPro ? '//minechemart.com' : '//s-m-dev.uid-devx.zuzuche.net',
    shareTitle: '租租车会员特权',
    shareContent: '免费享加油洗车7折起，旅行出行优惠8折起，更有海量境外免税店折扣特权...',
    shareIcon:
        'https://imgcdn5.zuzuche.com/m_tantu_image/20191115/6a6d2b5f5108d12122c9ffff432958a6.png',
    wechat_account_type: 131,
};

export default config;
