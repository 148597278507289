import { Fetch } from "@/utils";
type typeOptions = {
  [key: number]: any
}
//状态1未开奖 2中奖 3未中奖
const codeStatus: typeOptions = {
  1: {
    type: 'wating',
    msg: '待分配'
  },
  2: {
    type: 'get',
    msg: '分配成功'
  },
  3: {
    type: 'miss',
    msg: '分配失败'
  }
}
//活动状态 1未分配 2已分配
const activityStatus: typeOptions = {
  1: {
    type: 'wating',
    msg: '待分配'
  },
  2: {
    type: 'open',
    msg: '已公布'
  },
}
const activityCodes = {
  state: {
    loading: true,
    noRights: false,
    mycode: [],
    rule: '',
    showRule: false,
    showDownloadZzc: false,
    showResult: false,
    result: 0,
    hasWin: false // 是否曾中奖过
  },
  reducers: {
    set(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
    setShowRule(state: any, showRule: any) {
      return {
        ...state,
        showRule
      }
    },
    setShowDownloadZzc(state: any, showDownloadZzc: any) {
      return {
        ...state,
        showDownloadZzc
      }
    },
    setShowResult(state: any, showResult: any) {
      return {
        ...state,
        showResult
      }
    },
  },
  effects: (dispatch: any) => {
    return {
      async init(query: any) {
        const res = await Fetch(`/activity/draw/myCode?channel=${query.channel || ''}&orderId=${query.orderId || ''}`);
        // 正常流程，不写else ，是为了放出登录验证
        if (res && res.code === 1 && res.data) {
          const data = res.data;
          try {
            let codes: any = [];
            data.mycode.forEach((code: any, index: number) => {
              code.codeString = codeStatus[code.codeState];
              code.activityString = activityStatus[code.activityState];
              codes.push(code);
            })

            dispatch.activityCodes.set({
              mycode: codes,
              rule: data.rule,
              result: data.result,
              showResult: data.result === 1 || data.result === 3 ? true : false,
              noRights: !data.mycode.length ? true : false,
              hasWin: data.isWin === 1 ? true : false,
            });
          } catch (err) {
            console.log('err', err)
          }
        } else {
          console.log('err', res)
        }
      },
    }
  }
};


export default activityCodes;
