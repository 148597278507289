import { Fetch } from '@/utils';

// 获取中奖纪录
const getPrizeList = (params: any) =>
  Fetch.get('/activity/profit/myPrizeList', { params });

// 获取领取纪录
const getReceiveRecord = (params: any) =>
  Fetch.get('/activity/profit/prizeBox/getBoxUserLastGift', { params });

// 获取最新中奖用户信息
const latestWinners = (params?: any) =>
  Fetch.get('/activity/profit/latestWinners', { params });

// 获取团队收益
const myTeamList = (params?: any) =>
  Fetch.get('/activity/profit/myTeamList', { params });

// 我的邀请记录
const myInviteList = (params?: any) =>
  Fetch.get('/activity/profit/myInviteList', { params });

// 获取我的团队
const fetchMyTeamBoard = (params?: any) =>
  Fetch.get('/activity/profit/myTeamBoard', { params });

// 获取我的分享海报配置
const myInvitePosterConfig = (params?: any) =>
  Fetch.get('/activity/profit/myInvitePosterConfig', { params });

export default { 
  getPrizeList,
  getReceiveRecord,
  latestWinners,
  myTeamList,
  myInviteList,
  myInvitePosterConfig,
  fetchMyTeamBoard
}
