import { Fetch, getCookies } from '@/utils';

// 日期格式化
function dateFormat(fmt:any, date:any) {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? ((opt as any)[k]) : ((opt as any)[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

const treasureBox = {
  state: {
    baseConfig: '', // 奖品配置
    latestWinners: '', // 中奖马甲
    // 一天24个宝箱
    // type: 1  过期(包括抢到了和抢不到两种情况)
    // type: 2  当前(包括抢到了和抢不到两种情况)
    // type: 3  未开始
    exhibitList: '', 
    myPrize: '', // 我今天的中奖记录
    activityConfig: '', // 活动配置
    loopFlag: false, // 轮询是否结束
  },
  reducers: {
    setBaseConfig(state: any, data?: any) {
      return {
        ...state,
        baseConfig: data
      }
    },
    setLatestWinners(state: any, data?: any) {
      return {
        ...state,
        latestWinners: data
      }
    },
    setExhibitList(state: any, data?: any) {
      return {
        ...state,
        exhibitList: data
      }
    },
    setMyPrize(state: any, data?: any) {
      return {
        ...state,
        myPrize: data
      }
    },
    setActivityConfig(state: any, data?: any) {
      return {
        ...state,
        activityConfig: data
      }
    },
    setLoopFlag(state: any, flag?: any) {
      return {
        ...state,
        loopFlag: flag
      }
    }
  },
  effects: (dispatch: any) => {
    return {
      async getBaseConfig() {
        const { data } = await Fetch('/activity/profit/home', {
          method: 'GET'
        });
        try {
          (this as any).setBaseConfig(data);
        } catch (err) {
          (this as any).setBaseConfig('');
        }
      },
      async getLatestWinners() {
        const { data } = await Fetch('/activity/profit/indexLatestWinners', {
          method: 'GET'
        });
        try {
          let formatData:any[] = []
          data.map((item:any) => {
            formatData.push(`恭喜${item.nickname_encrypt}在${dateFormat('mm月dd日HH:MM分', new Date(item.created_at * 1000))}获得${item.prize_name}`)
          });
          (this as any).setLatestWinners(formatData);
        } catch (err) {
          (this as any).setLatestWinners('');
        }
      },
      async getMyPrize() {
        const { data } = await Fetch('/activity/profit/person/reward', {
          method: 'GET'
        });
        try {
          (this as any).setMyPrize(data);
          // const testData = {
          //   userAwarded: [{
          //     hour: 1,
          //     rewardName: '现金红包',
          //     rewardNum: '1000'
          //   }, {
          //     hour: 16,
          //     rewardName: '现金红包',
          //     rewardNum: '1000'
          //   }],
          //   nowTime: new Date().getTime() / 1000
          // };
          // (this as any).setMyPrize(testData);
          return data
        } catch (error) {
          (this as any).setMyPrize('');
          return ''
        }
      },
      async formatExhibitList () {
        // 未登录的时候给个默认
        let myPrize = {
          nowTime: Date.parse((new Date() as any))/1000,
          userAwarded: [],
          canOpen: false
        }
        if (getCookies('cdb_uid')) {
          myPrize = {
            ...myPrize,
            ...await (this as any).getMyPrize()
          }
        } 

        const currHour = new Date(myPrize.nowTime * 1000).getHours()
        let hourNum = 0;
        let exhibitListTemp:any[] = [];
        while(hourNum < 24) {
          exhibitListTemp.push({ type: '', hour: '', rewardName: '', rewardNum: '', rewardType: '', unit: '' })
          hourNum++;
        }
        exhibitListTemp.map((item: any, index: number) => {
          item.hour = index;
          if (item.hour < currHour) {
            item.type = 1
          } else if (item.hour === currHour) {
            item.type = 2
            item.canOpen = myPrize.canOpen
          } else {
            item.type = 3
          }
          myPrize.userAwarded.length && myPrize.userAwarded.map((pitem: any) => {
            if (item.hour + 1 === pitem.hour) {
              item.rewardName = pitem.rewardName
              item.rewardNum = pitem.rewardNum
              item.rewardType = pitem.type
              item.unit = pitem.unit
            }
          });
        });
        dispatch.treasureBox.setExhibitList(exhibitListTemp);
      },
      async openBox(payload: any) {
        const data = await Fetch(`/activity/profit/prizeBox/userOpenBox?date=${payload.date}&hourType=${payload.hourType}&tradeId=${payload.tradeId}`)
        return data
      },
      async checkBox(payload: any) {
        const data = await Fetch(`/activity/profit/prizeBox/checkUserCanOpenBox?date=${payload.date}&hourType=${payload.hourType}&onlyCheckStock=${payload.onlyCheckStock||''}`)
        return data
      },
      async openDoubleBox(payload: any) {
        const data = await Fetch(`/activity/profit/prizeBox/userOpenDoubleBox?referOpenId=${payload.id}&tradeId=${payload.tradeId}`)
        return data
      },
      async openExtraBox(payload: any) {
        const data = await Fetch(`/activity/profit/prizeBox/userGetExtraGift?date=${payload.date}&tradeId=${payload.tradeId}`)
        return data
      },
      async getActivityConfig() {
        const { data } = await Fetch('/activity/profit/prizeBox/config')
        try {
          (this as any).setActivityConfig(data);
          return data
        } catch (error) {
          (this as any).setActivityConfig('');
          return ''
        }
      },
      async loopQuery(payload: any) {
        const data = await Fetch(`activity/profit/callback/checkUserViewed?tradeId=${payload.tradeId}`)
        try {
          (this as any).setLoopFlag(data.data.isViewed);
        } catch (err) {
          (this as any).setLoopFlag(false);
        }
        return data
      },
      async initLoopFlag(payload: any) {
        (this as any).setLoopFlag(payload.flag);
      }
    }
  }
}

export default treasureBox;
