import { Fetch } from "@/utils";
import { Toast, ListView } from "antd-mobile";

const petrolBillHistory = {
    state: {
        list: '',
        loading: false,
        curPage: 1
    },
    reducers: {
        setList(state: any, data?: any) {
            return {
                ...state,
                list: data
            }
        },
        addList(state: any, data?: any) {
            return {
                ...state,
                list: state.list.concat(data)
            }
        },
        setCurPage(state: any, data?: any) {
            return {
                ...state,
                curPage: data
            }
        },
        setLoading(state: any, data?: any) {
            return {
                ...state,
                loading: data
            }
        }
    },
    effects: (dispatch: any) => ({
        async getHistoryBill(payload: any, rootState?: any) {
            const { page, pageSize } = payload;
            const { petrolBillHistory } = rootState;
            if(page === 1) {
                Toast.loading('', 0);
            }
            dispatch.petrolBillHistory.setLoading(true);
            const { data, code, msg } = await Fetch(
                `/station/getHistoryInvoices?page_size=${payload.pageSize || 10}&page=${payload.page || 1}`,
                { method: "GET" });
            Toast.hide();
            dispatch.petrolBillHistory.setLoading(false);
            if(code === 1 && data) {
                dispatch.petrolBillHistory.setCurPage(page);
                if(payload.page === 1) {
                    dispatch.petrolBillHistory.setList(data);
                } else {
                    dispatch.petrolBillHistory.addList(data);
                }
            } else if(page === 1) {
                Toast.info(msg || '网络错误，请稍后再试～');
            }
        }
    })
};

export default petrolBillHistory;