import { Fetch, Tools } from "@/utils";

const order = {
  state: {
    is_not_data: false,
    loading_text: '正在加载更多的订单...',
    hint: {
      is_show: false,
      text: '订单每30分钟更新一次,入住离店后30天内返现金额入账'
    },
    page: 1,
    page_size: 10,
    lists: [],
    realName: ''
  },
  reducers: {
    setNotList(state: any, data?: any) {
      return {
        ...state,
        is_not_data: data
      }
    },
    setList(state: any, data?: any) {
      return {
        ...state,
        page: data.page,
        lists: data.list,
        loading_text: data.text,
      };
    },
    setRealName(state: any, data?: any) {
      return {
        ...state,
        realName: data
      };
    }
  },
  effects: (dispatch: any) => {
    return {
      async getList(type = "init", rootState?: any) {
        const { page, page_size, lists } = rootState.order;
        const { data } = await Fetch(`/hotel/order/list`, {
          params: {
            page: type === 'init' ? 1 : page + 1,
            pageSize: page_size
          }
        });
        try {
          if (data.pagination.total === 0) {
            dispatch.order.setNotList(true);
          }
          const listData = (type === 'init' ? data.list : [...lists, ...data.list]);

          let textString = '正在加载更多的订单...'
          if (data.pagination.total <= listData.length) {
            textString = '没有更多了';
            (window as any).notMoreList = true;
          }

          dispatch.order.setList({
            page: type === 'init' ? 1 : page + 1,
            list: listData,
            text: textString
          });
          (window as any).isLoadingList = false;
        } catch (err) {
          console.log('err', err);
        }
      },
    }
  }
};

export default order;
