import { Fetch, Tools, Storage } from '@/utils';
import { Detector } from '@/utils';

const petrol = {
    state: {
        init_station: false, // 是否初始化完
        init_order: false, // 是否初始化完
        is_tab: +Tools.getUrlParam('tab') || 1, // 当前底部tab选中了哪个
        screen1: ['距离最近', '价格最低'], // 筛选列表的选项
        screen2: ['0号油', '92号油', '95号油', '98号油'], // 油号筛选的选项

        show_screen_select: 0, // 当前打开的筛选面板

        is_screen1: 1, // 当前筛选选中的值
        is_screen2: 2, // 当前油号选中的值

        lat: 0, // 定位
        lng: 0,
        loc_from:'phone', //经纬度坐标来源，默认phone: 通过手机插件获取的经纬度 search: 通过位置搜索获取的经纬度
        tag:'', //加油站的接口标签参数

        order_list: [], // 订单列表
        station_list: [], //油站列表
        station_tag: [], //油站标签列表

        station_page: 1, // 油站列表页数
        order_page: 1, // 订单列表页数

        list_text: '正在加载,请稍后...', // 底部文案
        notice_text: '',

        show_order_box: false,
        show_petrol_box: false,
        order_iphone_number: '',
        showLoadingText: true,
        not_location: {
            show: false,
            lists: ['非常抱歉,没有找到您周边的加油站', '请确认是否打开app定位服务'],
        },
        loading_location: {
            show: false,
            lists: ['正在获取定位信息'],
        },
        not_station: {
            show: false,
            lists: [
                '非常抱歉,没有找到您周边的加油站',
                '我们正在努力接入更多更优惠的加油站',
                '下次加油记得回来看看哟 ^ _ ^',
            ],
        },
        not_order: {
            show: false,
            lists: ['您还没有下过单哦，快去加油吧', '单单可享折扣，7折起'],
        },
        guide_info: {},
        litersSum: 0,
        discountAmountSum: 0,
        paymentCallbackData: '',
        show_download_zzc: false,
        loadingStatus:true
    },
    reducers: {
        setDownloadZzc(state: any, data: any) {
            return {
                ...state,
                show_download_zzc: data,
            };
        },
        setOrderBox(state: any, data: any) {
            return {
                ...state,
                show_order_box: data.show_order_box,
                order_iphone_number: data.order_iphone_number,
            };
        },
        petrolBox(state: any, data: any) {
            return {
                ...state,
                show_petrol_box: data,
            };
        },
        setText(state: any, data?: string) {
            return {
                ...state,
                list_text: data,
            };
        },
        addListPageL(state: any, data?: any) {
            const newData: any = {};
            if (data === 1) newData['station_page'] = state.station_page + 1;
            if (data === 2) newData['order_page'] = state.order_page + 1;

            return {
                ...state,
                ...newData,
            };
        },
        setGuideInfo(state: any, data?: any) {
            return {
                ...state,
                guideInfo: data,
            };
        },
        setStationList(state: any, data?: any) {
            return {
                ...state,
                station_list: data,
                init_station: true,
            };
        },
        setStationTag(state: any, data?: any) {
            return {
                ...state,
                station_tag: data,
            };
        },
        setOrderList(state: any, data?: any) {
            return {
                ...state,
                order_list: data,
                init_order: true,
            };
        },
        setLatLng(state: any, lat?: number, lng?: number) {
            return {
                ...state,
                lat,
                lng,
            };
        },
        isScreen(state: any, data: any) {
            if (state.show_screen_select === 2) {
                return {
                    ...state,
                    is_screen2: data,
                    show_screen_select: 0,
                    station_page: 1,
                };
            } else {
                return {
                    ...state,
                    is_screen1: data,
                    show_screen_select: 0,
                    station_page: 1,
                };
            }
        },
        setScreen(state: any, data: number) {
            return {
                ...state,
                show_screen_select: state.show_screen_select === data ? 0 : data,
            };
        },
        setTab(state: any, data?: number) {
            const newData: any = {};
            newData['is_tab'] = data;
            newData['list_text'] = '正在加载,请稍后...';

            if (data === 1) newData['station_page'] = 1;
            if (data === 2) newData['order_page'] = 1;

            // 兼容滚动回调
            (window as any).ZZC_IS_TAB_TAG = data;

            return {
                ...state,
                ...newData,
            };
        },
        noticeText(state: any, data?: any) {
            return {
                ...state,
                notice_text: data.info,
            };
        },
        discounts(state: any, data?: any) {
            return {
                ...state,
                litersSum: data.litersSum,
                discountAmountSum: data.discountAmountSum,
            };
        },
        paymentCallbackWeb(state: any, data?: any) {
            return {
                ...state,
                paymentCallbackData: data,
            };
        },
        showLoadingText(state: any, data?: any) {
            return {
                ...state,
                showLoadingText: data,
            };
        },
        setLocFrom(state: any, loc_from:string) {
            return {
                ...state,
                loc_from,
            };
        },
        setTag(state: any, tag:string) {
            // 选择标签的时候需要重置一下页码，不然会造成列表数据返回为空
            return {
                ...state,
                station_page:1,
                tag,
            };
        },
        loadingStatus(state:any,data:boolean){
            return {
                ...state,
               loadingStatus:data
            };
        }
    },
    effects: (dispatch: any) => ({
        async setPetrolBox(payload: any) {
            dispatch.petrol.petrolBox(payload.show_petrol_box);
        },
        async getStationList(type = 'init', rootState?: any) {
            const { petrol } = rootState;
            if (petrol.lat === 0 && petrol.lng === 0) {
                (window as any).isLoadingList = false;
                dispatch.petrol.setText('');
                dispatch.petrol.setStationList(petrol.station_list);
                dispatch.petrol.setStationTag(petrol.station_tag);
                return;
            }

            (window as any).isLoadingList = true;

            const params = {
                page: petrol.station_page,
                sort: petrol.is_screen1 === 2 ? 'price' : 'distance',
                oil_type: petrol.is_screen2,
                lat: petrol.lat,
                lng: petrol.lng,
                loc_from: petrol.loc_from, // 2021-06-11 新增
                tag:petrol.tag === "全部"? '': petrol.tag, // 2021-07-06 新增标签搜索
                code: Tools.getUrlParam('get_code') || 'default',
                channel: (Detector as any).browser.name === 'weixin' ? 'wechat' : '',
            };

            console.log('params:======>',params)

            if (type === 'init') {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }

            try {
                const { data, code } = await await Fetch(`/station/getList`, {
                    params,
                });
                // const { data, code }: any = {
                //     code: 1,
                //     msg: 'success',
                //     data: {
                //         list: [
                //             {
                //                 label: '预计40升省￥18.00',
                //                 station_pic:
                //                     'https://oss-zzc-uid-test.zuzuche.com/oil_pic/20191121/656_bd8f33ac9b5caaa50af49394b14cfa6b.jpg',
                //                 station_name: '苏州市江闽加油站',
                //                 price: '5.04',
                //                 oil_code: '92#',
                //                 lat: 30.904711779537767,
                //                 lng: 120.64213828253278,
                //                 url:
                //                     '//s-api-dev.uid-devx.zuzuche.net/api/station/redirect?id=656\u0026lat=30.154265248397\u0026lng=119.79435814607',
                //                 reduction: '0.45',
                //                 reduction_forty: '18.00',
                //                 address: '西环路与舜湖西路交汇处北金豪门附近',
                //                 distance: 117.46,
                //                 app_require: 0,
                //             },
                //         ],
                //         page_count: 1,
                //         guide_info: {
                //             title: '加油福利-单单享直降优惠价',
                //             sub_title: '此特权为APP专属特权-加油7折起\u003Cbr\u003E单单有折扣！',
                //             btn_text: '租租车APP专属特权 点击下载',
                //             link:
                //                 'https://a.app.qq.com/o/simple.jsp?pkgname=com.zuzuChe\u0026ckey=CK1443629377604',
                //         },
                //     },
                // };
                let newData: any = [];
                let newTag: any = [];
                if (code === 1) {
                    if (data.list.length === 0) {
                        dispatch.petrol.addListPageL((window as any).ZZC_IS_TAB_TAG);
                        this.getStationList(type, rootState);
                        return;
                    }

                    dispatch.petrol.setGuideInfo(data.guide_info);

                    newData = [...data.list];
                    // 2020-07-06 标签做一下映射
                    newTag = data.tags.map((i:any)=>i.name);

                    if (petrol.station_page >= data.page_count)
                        dispatch.petrol.setText('附近没有更多的油站了');
                } else {
                    dispatch.petrol.setText(type === 'add' ? '附近没有更多的油站了' : '');
                }

                dispatch.petrol.setStationList(
                    type === 'add' ? [...petrol.station_list, ...newData] : newData
                );
                dispatch.petrol.setStationTag(
                    type === 'add' ? [...petrol.station_tag, ...newTag] : newTag
                );
            } catch (err) {
                console.log('err', err);
                dispatch.petrol.setText(type === 'add' ? '附近没有更多的油站了' : '');
            }
            (window as any).isLoadingList = false;
            const petrolBox = Storage.get('petrol_box');
            if (petrolBox !== '1') {
                dispatch.petrol.petrolBox(true);
            }
        },
        async getOrderList(type = 'init', rootState?: any) {
            (window as any).isLoadingList = true;
            const { petrol } = rootState;

            const params = {
                page: petrol.order_page,
                supplier: 'All',
            };

            if (type === 'init') {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }

            try {
                const { data, code } = await Fetch(`/station/getFuelHistoryOrderList`, {
                    params,
                });
                let newData: any = [];
                if (code === 1) {
                    newData = [...data.orderList];

                    if (data.orderList.length < 10) dispatch.petrol.setText('没有更多的订单了');
                } else {
                    dispatch.petrol.setText(type === 'add' ? '没有更多的订单了' : '');
                }
                dispatch.petrol.discounts({
                    litersSum: data.litersSum,
                    discountAmountSum: data.discountAmountSum,
                });
                dispatch.petrol.setOrderList(
                    type === 'add' ? [...petrol.order_list, ...newData] : newData
                );
            } catch (error) {
                dispatch.petrol.setText(type === 'add' ? '没有更多的订单了' : '');
                dispatch.petrol.showLoadingText(true);
            }

            (window as any).isLoadingList = false;
        },
        async getNoticeText() {
            const { data } = await Fetch(`/station/newOrderInfo`, { method: 'GET' });
            // console.log(data)
            if (data) {
                (this as any).noticeText(data);
            } else {
                (this as any).noticeText('');
            }
        },
        async getpaymentCallbackData() {
            const { data } = await Fetch(`/station/paymentCallbackWeb`, {
                method: 'GET',
            });
            try {
                (this as any).paymentCallbackWeb(data);
            } catch (err) {
                (this as any).paymentCallbackWeb('');
            }
        },
        async getOrderShareWeb(payload: any) {
            const {
                data,
            } = await Fetch(
                `/station/getOrderShareWeb?order_id=${payload.orderId}&supplier=${payload.supplier}`,
                { method: 'GET' }
            );
            try {
                (this as any).paymentCallbackWeb(data);
            } catch (err) {
                (this as any).paymentCallbackWeb('');
            }
        },
        setLoading(payload: boolean){
            (this as any).loadingStatus(payload);
          }
      
    }),
};

export default petrol;
