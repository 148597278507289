

import config from '@/config/config';
const domain = config.domain;

export default function appShare(pageConfig: any) {
  console.log(pageConfig);
  try {
    (window as any).zzc.call("appCustomRightBarList", {
      customItems: [
        {
          type: "home",
          title: "首页",
          url: `${window.location.protocol}${domain}/`,
          icon:
            "https://imgcdn5.zuzuche.com/m_tantu_image/20191204/e3fa31c7b3f4c16f46dc50296ee90cdc.png"
        },
        {
          type: "share",
          title: "分享",
          shareTitle: pageConfig.shareTitle,
          shareContent: pageConfig.shareContent,
          shareIcon: pageConfig.shareIcon,
          shareURL: pageConfig.shareURL,
          path: "",
          sharePlatform: pageConfig.sharePlatform || ["wxsession", "wxtimeline", "sina", "qq", "qqzone", "copy"]
        }
      ],
      listener: function (response: any) {
        console.log(response, "listener");
      },
      success: function (response: any) {
        console.log(response, "success");
      }
    });
  } catch (error) {
    console.log(error);
  }
}
