/**
 * cookie：cookie资源不能滥用，而且要被统一管理
 * 注意：整个项目的cookie都必须在此模块定义；只能使用本模块接口
 */
import Cookie from 'js-cookie';

// 白名单：可以使用的cookie名称
const WHITE_LIST = [
    'cdb_uid', // 用户ID，
    'wx_dx_profit-hb-invite',
    'lang',
];

function warn(name: string) {
    if (!WHITE_LIST.includes(name)) {
        // eslint-disable-next-line
        console.warn(`警告：白名单没有该cookie(${name})`);
    }
}

export function getCookies(name: string) {
    warn(name);
    return Cookie.get(name);
}

export function setCookies(name: string, value: string, options: object) {
    warn(name);
    return Cookie.set(name, value, options);
}

export function removeCookies(name: string, options: object) {
    warn(name);
    return Cookie.remove(name, options);
}
