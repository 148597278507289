import { Fetch } from "@/utils";
import { Toast } from "antd-mobile";
import { isZzcLogin } from '@/utils';
import initWeChat, { wxShare } from '@/utils/wechat';
import { setTitle } from '@/native';
import appShare from './appShare';

const activityConfig = {
  state: {
    loading: true,
    noRights: true,
    infoToken: '',
    submitInfo: {
      submitCode: '',
      submitPhone: '',
      submitName: '',
      submitAddressProvince: '',
      submitAddressCity: '',
      submitAddressDistrict: '',
      submitAddressDetail: ''
    },
    page_lottery: {}, // 抽奖码页面
    page_lottery_result: {}, // 开奖结果页
    page_address_success: {}, // 提交地址成功
    page_address_resubmit: {}, //
    page_address_index: {}, // 提交地址
    page_address_jump: {}, // 引导页面
    page_share: {},
    page_invite: {}, // 邀请页面的
  },

  reducers: {
    set(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
  },
  effects: (dispatch: any) => {
    return {
      async init(query: any) {
        const flag = query.token ? '' : (query.flag || 'no_user');
        const res = await Fetch(`/coupon/collectMsg/getIndex?code=${query.codeId || 'LIXC1WC16U1Q'}&token=${query.token || ''}&flag=${flag}`);
        // 正常流程，不写else ，是为了放出登录验证
        if (res && res.code === 1 && res.data) {
          const data = res.data;
          // 未登录用户
          if (data.userId === 0) {
            Toast.offline('您尚未登陆，请登陆后再操作', 3, () => {
              isZzcLogin({ ref_url: window.location.href });
            });
            return;
          }

          try {
            dispatch.activityConfig.set({
              ...data.config,
              noRights: !data.submitInfo ? true : false,
              submitInfo: data.submitInfo
            });


            // 微信分享配置  -- 每个页面用每个页面自己的配置
            const pageConfig = data.config[query.page || 'page_address_success'];
            setTitle(pageConfig.page_title);
            if (query.noShare) { return; }

            initWeChat()
              .then(() => {
                wxShare({
                  title: pageConfig.wx_share.title || '防护口罩，限量发放', // 分享标题
                  desc: pageConfig.wx_share.sub_title || '武汉加油，中国加油',
                  link: pageConfig.wx_share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: pageConfig.wx_share.img, // 分享图标
                  success: function () { }
                })
              })
              .catch(() => { });

            // app 配置分享
            appShare({
              shareTitle: pageConfig.wx_share.title,
              shareContent: pageConfig.wx_share.sub_title,
              shareIcon: pageConfig.wx_share.img,
              shareURL: pageConfig.wx_share.link,
            });

          } catch (err) { }
        }
      },
    }
  }
};




export default activityConfig;
