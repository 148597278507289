import Detector from '@/utils/detector';
import Tools from '@/utils/tools';

const isZuzuche = Tools.isZuzuche();

const backToHome = (e?: any, tanIndex?: string) => {
    if (isZuzuche) {
        let initTabIndex = '';
        const browserName = (Detector as any).browser.name;
        if (browserName === 'zuzuche') {
            initTabIndex = 'home';
        }
        if (browserName === 'zuzucherewards') {
            initTabIndex = 'home';
        }
        (window as any).zzc.call('base_backToHome', {
            tabIndex: tanIndex || initTabIndex,
            success: function() {},
        });
    } else {
        window.location.href = '/';
    }
};

/**
 * 返回前一个webview页面
 * http://wiki.int.zuzuche.info/pages/viewpage.action?pageId=7668101
 */
export function backToPreviousPage() {
    if (isZuzuche) {
        (window as any).zzc.call('backtopreviouspage', {
            success: function(o: any) {
                console.log(o);
            },
        });
    } else {
        window.history.length > 1 ? window.history.go(-1) : (window.location.href = '/');
    }
}

// 打开appStore
const openAppStore = (id?: string) => {
    if (!(window as any).zzc) {
        return;
    }
    (window as any).zzc.call('openAppMarket', {
        appName: id,
        success: function(response: any) {
            console.log(response);
        },
    });
};

const getVersionCheck = (fn?: VoidFunction) => {
    if (!(window as any).zzc) {
        return;
    }
    (window as any).zzc.call('getVersionCheck', {
        success: function(response: any) {
            console.log(response);
            fn && fn();
        },
    });
};

const logout = (errFn?: any, successFn?: any) => {
    if (!(window as any).zzc) {
        errFn && errFn();
        return;
    }
    (window as any).zzc.call('logout', {
        success: function(response: any) {
            if (response.state === 0) {
                (window as any).zzc.call('page_linkTo', {
                    url: '/main/loginPage',
                    success: function() {
                        successFn && successFn();
                    },
                });
            } else {
                errFn && errFn();
            }
        },
    });
};

const onPageLinkTo = (refUrl: string) => {
    if (Tools.isZuzuche() && (window as any).zzc && (window as any).zzc.pluginState === 'ready') {
        // 租租车app环境内
        (window as any).zzc.call('page_linkTo', {
            url: `${encodeURIComponent(refUrl)}`,
            success: (response: any) => console.log('登陆唤起成功', response),
        });
    } else {
        // 其实环境跳h5登陆
        window.location.href = `${encodeURIComponent(refUrl)}`;
    }
};

const setTitle = (title: string) => {
    (window as any).zzc.call('base_webviewTitle', {
        title,
        success: function() {},
    });
    document.title = title;
};

export { backToHome, setTitle, openAppStore, getVersionCheck, logout, onPageLinkTo };
