import { Fetch } from "@/utils";
import { Toast, ListView } from "antd-mobile";

const petrolBillOrders = {
    state: {
        curPage: 1,
        loading: false,
        list: [],
        botMsg: '',
        isAllSelected: false
    },
    reducers: {
        setCurPage(state: any, data?: any) {
            return {
                ...state,
                curPage: data
            }
        },
        setList(state: any, data?: any) {
            return {
                ...state,
                list: data
            }
        },
        addList(state: any, data?: any) {
            return {
                ...state,
                list: state.list.concat(data)
            }
        },
        setLoading(state: any, data?: any) {
            return {
                ...state,
                loading: data
            }
        },
        setBotMsg(state: any, data?: any) {
            return {
                ...state,
                botMsg: data
            }
        },
        setIsAllSelected(state: any, data?: any) {
            return {
                ...state,
                isAllSelected: data
            }
        },
        toggleOrderSelected(state: any, id?: any) {
            const newList = state.list.map((l: any) => {
                return {
                    ...l,
                    selected: id === l.order_id ? !l.selected : false
                }
            })
            return {
                ...state,
                list: newList,
                isAllSelected: newList.every((l: any) => l && l.selected)
            }
        },
        toggleAllOrderSelected(state: any) {
            const newList = state.list.map((l: any, all: boolean) => {
                return {
                    ...l,
                    selected: !state.isAllSelected
                }
            });
            return {
                ...state,
                list: newList,
                isAllSelected: !state.isAllSelected
            }
        }
    },
    effects: (dispatch: any) => ({
        async getUnAppliedBillOrders(payload: any, rootState?: any) {
            const { page, pageSize } = payload;
            if (page === 1) {
                Toast.loading('', 0);
            }
            dispatch.petrolBillOrders.setLoading(true);
            const { petrolBillOrders } = rootState;
            const { data, code, msg } = await Fetch(
                `/station/getUnAppliedInvoiceOrders?page_size=${pageSize || 10}&page=${page || 1}`,
                { method: "GET" });
            Toast.hide();
 
            dispatch.petrolBillOrders.setLoading(false);
            if (code === 1 && data) {
                dispatch.petrolBillOrders.setCurPage(page);
                const handledData = data.map((d: any) => {
                    return {
                        ...d,
                        selected: false
                    }
                });
                if (page === 1) {
                    dispatch.petrolBillOrders.setList(handledData);
                } else {
                    dispatch.petrolBillOrders.addList(handledData);
                }
            } else if(page === 1) {
                dispatch.petrolBillOrders.setList([]);
                Toast.info(msg || '网络错误，请稍后再试～');
            }
        }
    })
};

export default petrolBillOrders;