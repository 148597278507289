/**
 * 微信JS-SDK接入
 * 
 */

const wechatConfig = {
	postUrl: 'https://s-api.zuzuche.com/api/share/wechat/sdkConfig',
	accountType: window.location.host === 'dsqcjy.com' ? 131 : 127,
	jsApiList: [
		'checkJsApi',
		'onMenuShareTimeline',
		'onMenuShareAppMessage',
		'onMenuShareQQ',
		'onMenuShareWeibo',
		'hideMenuItems',
		'showMenuItems',
		'hideAllNonBaseMenuItem',
		'showAllNonBaseMenuItem',
		'translateVoice',
		'startRecord',
		'stopRecord',
		'onRecordEnd',
		'playVoice',
		'pauseVoice',
		'stopVoice',
		'uploadVoice',
		'downloadVoice',
		'chooseImage',
		'previewImage',
		'uploadImage',
		'downloadImage',
		'getNetworkType',
		'openLocation',
		'getLocation',
		'hideOptionMenu',
		'showOptionMenu',
		'closeWindow',
		'scanQRCode',
		'chooseWXPay',
		'openProductSpecificView',
		'addCard',
		'chooseCard',
		'openCard',
		'updateAppMessageShareData',
		'updateTimelineShareData',
	]
}


function loadWeChatSdk() {
	return new Promise((resolve, reject) => {
		let script = document.createElement('script');
		script.type = 'text\/javascript';
		script.src = '//res.wx.qq.com/open/js/jweixin-1.4.0.js';
		script.onload = function (res) {
			resolve(res);
		};
		script.onerror = function (err) {
			reject(err);
		}
		document.body.appendChild(script);
	})
}

function getWeCahtConfig() {
	return new Promise((resolve, reject) => {
		let xhr = new XMLHttpRequest();
		xhr.responseType = "json";
		xhr.open("post", wechatConfig.postUrl, true);

		xhr.onload = function () {
			if (this.response.code !== 1) {
				reject()
				return;
			}
			resolve(this.response.data);
		}

		xhr.onerror = error => {
			reject(error);
			console.log(error);
		}; //请求失败
		xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;");
		xhr.send(`account_type=${wechatConfig.accountType}&url=${encodeURIComponent(window.location.href)}`); //开始上传，发送form数据
	})
}

let hasLoad = false;
export default function initWeChat() {
	return new Promise((resolve, reject) => {
		if (!navigator.userAgent.match(/MicroMessenger\/([\d.]+)/i)) {
			console.log('您不在微信环境内哦哦哦～');
			reject()
			return;
		}


		// 如果已经加载过了，直接reslove，不发第二次请求
		if (hasLoad && (window as any).wx) {
			resolve();
			return;
		}

		Promise.all([loadWeChatSdk(), getWeCahtConfig()]).then((res: any) => {
			(window as any).wx.config({
				// debug: true,
				appId: res[1].appId,
				timestamp: res[1].timestamp,
				nonceStr: res[1].nonceStr,
				signature: res[1].signature,
				jsApiList: wechatConfig.jsApiList
			});

			(window as any).wx.ready(function () {
				hasLoad = true;
				resolve()
			});
		})
	})
}

// 微信分享
export function wxShare(options: any) {
	if ((window as any).wx) {
		(window as any).wx.updateAppMessageShareData(options);
		(window as any).wx.updateTimelineShareData(options);
	}
}
