import Service from "../service";


const posterShare = {
  state: {
    loading: true,
    imgsrc: [],
    config: {}
  },
  reducers: {
    set(state: any, data: any) {
      return {
        ...state,
        config: { ...data },
        loading: false
      }
    },
    setImgsrc(state: any, src: string, index: number) {
      let imgs = [...state.imgsrc];
      imgs[index] = src;
      return {
        ...state,
        imgsrc: [...imgs]
      }
    },
  },
  effects: (dispatch: any) => {
    return {
      async init() {
        const {code, data} = await Service.myInvitePosterConfig();
        if (code === 1 && !!data) {
          dispatch.posterShare.set({ ...data });
        } else {
          dispatch.posterShare.set();
        }
      }
    }
  }
};

export default posterShare;