import React from 'react';
import styles from './index.module.scss'

const Loading = function () {
  return <div>
  <div className={styles['load-container']}>
    <div className={styles['lds-spinner']}><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/><div/></div>
  </div>
</div>
};

export default Loading;
