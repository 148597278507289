import { getCookies } from "./cookies";
import { Fetch, Storage } from "@/utils";

// 判断是否是租租车的域名
const hostMatch = window.location.host.match(/((\w)+.zuzuche.\w{3})/) || []
const isLocal = window.location.host.indexOf('localhost') > -1;
const isZuzucheEnv = hostMatch.length ? true : false || isLocal;

export function isLogin() {
  if (isZuzucheEnv) {
    return !!getCookies("cdb_uid");
  } else {
    // 登录 未登录
    const loginToken = Storage.get('loginToken');
    if (loginToken && loginToken.addTimeStamp && Date.now() - loginToken.addTimeStamp < 20 * 60 * 1000) {
      return true
    } else {
      return false
    }
  }
}



export async function checkTokenLogin() {
  // 如果是租租车的域名，直接执行callback
  if (isZuzucheEnv) {
    return true;
  }

  // 判断是否请求过token，并且请求时间间隔少于 20分钟，直接执行
  const loginToken = Storage.get('loginToken');
  if (loginToken && loginToken.addTimeStamp && Date.now() - loginToken.addTimeStamp < 20 * 60 * 1000) {
    return true
  }

  // 请求token
  const { data } = await Fetch('/user/profile/token');
  if (data && data.token) {
    Storage.set('loginToken', {
      token: data.token,
      addTimeStamp: Date.now()
    });
    return true
  } else {
    //还不知道应该做什么
    return false;
  }
};
