import { init } from "@rematch/core";
import thunk from "redux-thunk";
import logger from "redux-logger";
import createLoadingPlugin from "@rematch/loading";

/**
 * 加载业务员模块
 */
import brand from "@/pages/Brand/model";
import carOwner from "@/pages/CarOwner/model";
// import home from "@/pages/Home/model";
// 分离首页
import petrol from "@/pages/Petrol/model";
import food from "@/pages/Food/model";
import recharge from "@/pages/Recharge/model";
import rechargeOrders from "@/pages/Recharge/model/orders";
import rechargeOrderDetail from "@/pages/Recharge/model/orderDetail";
import meituan from "@/pages/Meituan/model";
import order from "@/pages/Order/model";
import activityAddress from "@/pages/Activity/model/address";
import activityConfig from "@/pages/Activity/model/config"
import activityCodes from "@/pages/Activity/model/codes";
import activityHistory from "@/pages/Activity/model/history";
import activityInvite from "@/pages/Activity/model/invite";
import treasureBox from '@/pages/TreasureBox/model';
import treasureBoxV2 from '@/pages/TreasureBoxV2/model';
import posterShare from '@/pages/TreasureBox/model/share';
import treasureBoxAddress from '@/pages/TreasureBox/model/address';
import petrolBill from '@/pages/PetrolBill/model';
import petrolBillEmail from '@/pages/PetrolBillEmail/model';
import petrolBillHistory from '@/pages/PetrolBillHistory/model';
import petrolBillOrders from '@/pages/PetrolBillOrders/model';

const options = {};
const loading = createLoadingPlugin(options);

let PATH_URL = process.env.REACT_APP_PATH_CONFIG;
let middleArray = [thunk, logger];

if (PATH_URL !== "dev") {
  middleArray = [thunk];
}

const Models = {
  carOwner,
  brand,
  // home,
  food,
  petrol,
  petrolBill,
  petrolBillHistory,
  petrolBillEmail,
  petrolBillOrders,
  recharge,
  rechargeOrders,
  rechargeOrderDetail,
  meituan,
  order,
  activityConfig,
  activityCodes,
  activityAddress,
  activityHistory,
  activityInvite,
  treasureBox,
  treasureBoxV2,
  posterShare,
  treasureBoxAddress
};

const store = init({
  models: {
    ...Models
  },
  redux: {
    devtoolOptions: options,
    middlewares: middleArray
  },
  plugins: [loading]
});

export default store;
