import { isLogin } from './auth';
// import Detector from "./detector";
import Fetch from './fetch';
import { logout } from '@/native';
import Config from '../config/config';
import * as Cookies from '@/utils/cookies';
import Tools from '@/utils/tools';
import * as Sentry from '@sentry/browser';

const { dozzc } = Config;

/**
 * isZzcLogin
 * 校验是否处于登陆状态
 *
 * limit 决定是否限流
 */

const loginSuccess = (data?: any) => {
    console.log('登录成功');
    window.location.reload();
};
export function isZzcLogin(options: any, success = loginSuccess, limit = false) {
    const noLogin = isLogin() === false ? true : false;
    console.log(noLogin, isLogin());

    const successFun = async () => {
        console.log('options.needGetUserInfo',options.needGetUserInfo, noLogin);
        if (options.needGetUserInfo && noLogin) {
            const { data, code } = await Fetch('/user/profile/get');
            if (data && code === 1) {
                success(data);
            }
        } else {
            success();
        }
    };

    const loginFunc = function() {
        const baseUrl = `${dozzc}/account/login.php?`;
        const params = {
            ...options.params,
        };

        const loginUrl =
            baseUrl +
            Object.keys(params)
                .map(key => {
                    return `${key}=${(params as any)[key]}`;
                })
                .join('&') +
            '&ref=';

        const refUrl = options.refUrl || window.location.href.replace(/#\/?$/, '');

        if (
            Tools.isZuzuche() &&
            (window as any).zzc &&
            (window as any).zzc.pluginState === 'ready'
        ) {
            // 租租车app环境内
            if (!Tools.isZuzucheRewards()) {
                (window as any).zzc.call('onLoginStatusChange', {
                    listener: function(response: any) {
                        console.log('app登陆回调：', response);
                        response.type === '1' && successFun();
                    },
                    success: (response: any) => console.log('登陆状态改变监控设置成功', response),
                });

                (window as any).zzc.call('page_linkTo', {
                    url: `/login/v2/?sourceUrl=${encodeURIComponent(refUrl)}`,
                    success: (response: any) => console.log('登陆唤起成功', response),
                });
            } else {
                try {
                    const params = {
                        cookie: Cookies.getCookies('cdb_uid') || '',
                        token: Cookies.getCookies('cdb_uid_unique_token') || '',
                        path: window.location.href,
                        time: Tools.dateFormat('YYYY-mm-dd HH-MM-SS', new Date()),
                    };
                    (Sentry as any).captureException(params);
                } catch (error) {
                    (Sentry as any).captureException(error);
                }
                logout();
            }
        } else {
            console.log('进入else语句块',  `${loginUrl}${encodeURIComponent(refUrl)}`)
            // 其他环境跳h5登陆
            window.location.href = `${loginUrl}${encodeURIComponent(refUrl)}`;
        }
    };

    // 如果未登陆了,先去登陆，登录成功之后判断是否需要重新更新用户信息， 再执行成功的方法
    console.log('noLogin===>',noLogin)
    if (noLogin) {
        if (limit) {
            Fetch('/privilege/flow/limit')
                .then((res: any) => {
                    if (res && res.data && res.data.limit === 1) {
                        window.location.href = '/flowLimit';
                        return;
                    }
                    loginFunc();
                })
                .catch((e: any) => {
                    // window.location.href = "/flowLimit";
                });
        } else {
            loginFunc();
        }
    } else {
        successFun();
    }
}
