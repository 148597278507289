import Service from "../service";
// import { Tools } from "@/utils";

const food = {
  state: {
    title: "",
    goodsDetail: ""
  },
  reducers: {
    setGoodsDetail(state: any, data?: object) {
      return {
        ...state,
        goodsDetail: data
      };
    },
    // setTitle(state: any, data?: object) {
    //   return {
    //     ...state,
    //     title: data
    //   };
    // },
    // setAddStatus(state: any, data?: object) {
    //   return {
    //     ...state,
    //     addStatus: data
    //   };
    // }
  },
  effects: () => ({
    async getGoodsDetailModel(payload: object) {
      const { data, code} = await Service.getGoodsDetail(payload);
      if (!!data && code === 1) {
        (this as any).setGoodsDetail(data);
      } else {
        (this as any).setGoodsDetail({
          goodsError: true
        });
      }
    },
  })
};

export default food;
