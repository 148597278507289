import { Fetch } from "@/utils";
import { Toast } from "antd-mobile";
import { isZzcLogin, Tools } from '@/utils';
import qs from 'qs';
import axios from "axios";
import Config from "@/config/config";
import { setTitle } from "@/native";
import initWeChat, { wxShare } from '@/utils/wechat';
import appShare from '@/pages/Activity/model/appShare';

type typeOptions = {
  [key: string]: any
}

const validateMsg: typeOptions = {
  'code': {
    type: 'code',
    msg: '请输入兑换码'
  },
  'name': {
    type: 'name',
    msg: '请输入收货人姓名'
  },
  'phone': {
    type: 'phone',
    msg: '请输入联系人手机号码'
  },
  'area': {
    type: 'area',
    msg: '请选择收件地址'
  },
  'detail': {
    type: 'detail',
    msg: '请输入详细地址'
  }
};

const treasureBoxAddress = {
  state: {
    loading: true,
    showConfirm: false,
    hasBook: false,
    infoToken: '',
    btnStatus: '',
    validateMsg,
    formValues: {
      name: '',
      phone: '',
      area: {
        value: [],
        province: '',
        city: '',
        distrct: '',
        addresses: ''
      },
      detail: '',
    },
    config: {},
    submitCode: Tools.getUrlParam('submitCode')
  },
  reducers: {
    set(state: any, data: any) {

      // 如果有初始化的值，覆盖
      const { formValues } = state;
      if (data.initInfo) {
        if (data.initInfo.submitPhone) {
          formValues.phone = data.initInfo.submitPhone
        }
      }

      return {
        ...state,
        ...data,
        formValues,
        loading: false
      }
    },
    setShowConfirm(state: any, showConfirm: boolean) {
      return {
        ...state,
        showConfirm
      }
    },
    setFormValues(state: any, type: any, value: any) {
      let { formValues } = state;
      formValues[type] = value;

      // 验证是否全部不为空
      let btnStatus = 'active';
      for (let key in formValues) {
        let _value = key === 'area' ? formValues[key].addresses : formValues[key];
        if (_value === '') {
          btnStatus = ''
        }
      }

      return {
        ...state,
        formValues,
        btnStatus
      }
    },
  },
  effects: (dispatch: any) => {
    return {
      async init(codeId: any) {
        const { data, code } = await Fetch(`/coupon/collectMsg/getIndex?code=${codeId || 'LIXC1WC16U1Q'}`);
        // 正常流程，不写else ，是为了放出登录验证
        if (code === 1 && data) {
          console.log(8888)
          // 未登录用户
          if (data.userId === 0) {
            Toast.offline('您尚未登陆，请登陆后再操作', 3, () => {
              isZzcLogin({ ref_url: window.location.href });
            });
            return;
          }
          try {
            dispatch.treasureBoxAddress.set({
              ...data,
              // hasBook: data.infoToken && data.infoToken !== '' ? true : false // 进入页面必须填写地址
            });

            // 微信分享配置 
            const pageConfig = data.config['page_address_index'];
            setTitle(pageConfig.page_title);
            initWeChat()
              .then(() => {
                wxShare({
                  title: pageConfig.wx_share.title || '防护口罩，限量发放', // 分享标题
                  desc: pageConfig.wx_share.sub_title || '武汉加油，中国加油',
                  link: pageConfig.wx_share.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                  imgUrl: pageConfig.wx_share.img, // 分享图标
                  success: function () { }
                })
              })
              .catch(() => { });

            // app 配置分享
            appShare({
              shareTitle: pageConfig.wx_share.title,
              shareContent: pageConfig.wx_share.sub_title,
              shareIcon: pageConfig.wx_share.img,
              shareURL: pageConfig.wx_share.link,
            });
          } catch (err) { }
        }
      },

      async submit(payload: any, rootState: any) {
        Toast.info('提交中...');
        const { treasureBoxAddress } = rootState;
        const { name, phone, area, detail } = treasureBoxAddress.formValues;
        console.log(treasureBoxAddress.submitCode)
        
        const params = qs.stringify({
          submitToken: treasureBoxAddress.submitToken,
          submitPhone: phone,
          submitName: name,
          submitAddressProvince: area.province,
          submitAddressCity: area.city,
          submitAddressDistrict: area.distrct,
          submitAddressDetail: detail,
          submitCode: treasureBoxAddress.submitCode
        });

        axios({
          url: `${Config.api}coupon/collectMsg/postMsg`,
          method: "POST",
          withCredentials: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: params
        })
          .then(function (response) {
            const { status, data } = response;
            if (status === 200 && data) {
              if (data.code === 1 && data.data && data.data.code) {
                window.location.href = `/activity/success/?code=${data.data.code}`;
              } else {
                Toast.info(data.msg || '提交失败请重试', 2);
              }
            } else {
              Toast.info(data.msg || '提交失败请重试', 2);
            }
          })
          .catch(function (error) {
            console.log(error);
            Toast.info('提交失败请重试', 2);
          });
      }
    }
  }
};

export default treasureBoxAddress;
