import { Fetch } from "@/utils";
import { Toast } from "antd-mobile";
import { backToPreviousPage } from "@/native";

const petrolBillEmail = {
    state: {
        email: '',
    },
    reducers: {
        setEmail(state: any, data?: any) {
            return {
                ...state,
                email: data
            }
        },
    },
    effects: (dispatch: any) => ({
        async modifyEmail(payload: any) {
            Toast.loading('', 0);
            const { data, code, msg } = await Fetch(
                `/station/updateInvoiceEmail`,
                {
                    method: "POST",
                    data: payload,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            // console.log(data)
            Toast.hide();
            if (code === 1) {
                Toast.info('修改成功!');
                setTimeout(() => {
                    backToPreviousPage();
                }, 2000);
            } else {
                Toast.info(msg || '网络错误，请稍后再试～');
            }
        },
    })
};

export default petrolBillEmail;