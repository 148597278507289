import { Fetch, isZzcLogin } from "@/utils";
import { Toast } from "antd-mobile";


const activityHistory = {
  state: {
    loading: true,
    noRights: false,
    history: [],
    indexs: {},
    status: 'win', // win | miss | no_join
    hasWin: true, // 是否曾经中奖过
    showAddressBox: false, // 是否需要显示填写地址弹窗
  },
  reducers: {
    set(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
    setToggle(state: any, index: number) {
      const newIndexs = state.indexs;
      if (newIndexs[index]) {
        delete newIndexs[index];
      } else {
        newIndexs[index] = true;
      }
      return {
        ...state,
        indexes: newIndexs,
        loading: false
      }
    },

    setShowAddressBox(state: any, showAddressBox: boolean) {
      localStorage.setItem('activity-show-addresses', JSON.stringify({ time: new Date().getTime() }));

      return {
        ...state,
        showAddressBox
      }
    }
  },
  effects: (dispatch: any) => {
    return {
      async init() {
        const { data, code } = await Fetch(`/activity/draw/posterList`);
        // 正常流程，不写else ，是为了放出登录验证
        if (code == 1 && data) {
          // 未登录用户
          if (data.userId === 0) {
            Toast.offline('您尚未登陆，请登陆后再操作', 3, () => {
              isZzcLogin({ ref_url: window.location.href });
            });
            return;
          }

          try {
            dispatch.activityHistory.set({
              ...data,
              status: data.history.length === 0 ? 'none' : data.status,
              showAddressBox: data.isMsg === 1 ? true : false,
              //showAddressBox: data.status === 'win' ? checkShowAddresses() : false,
              hasWin: data.isWin === 1 ? true : false,
            });
          } catch (err) { }
        }
      },
    }
  }
};




// 检查是否需要弹窗 -- 基于当期已经中奖前提
// 12:00为一个时间节点
function checkShowAddresses() {
  let showAddressBox = false;

  // 当前时间小于 12点， 命中周期为昨天12:00 到今天12:00 
  const checkTime = getCheckTime(11);
  const now = new Date().getTime();
  let start, end;
  if (now - checkTime < 0) {
    start = getCheckTime(-12);
    end = getCheckTime(12);
  } else {
    start = getCheckTime(12);
    end = getCheckTime(36);
  }


  let lastShow = localStorage.getItem('activity-show-addresses') || '{}';
  lastShow = JSON.parse(lastShow);

  // 如果已经弹窗过，检查当前时间是否在下个周期，并且弹窗是上个周期的，
  if (lastShow && (lastShow as any).time) {
    const inRange = now - start > 0; // 当前时间在这个周期里
    const noShow = (lastShow as any).time - start < 0; // 弹窗是上个周期的
    if (inRange && noShow) {
      showAddressBox = true;
    }
  } else {
    // 没有弹窗过，并且还在当前周期内
    if (now - end < 0) {
      showAddressBox = true;
    }
  }

  return showAddressBox;
}

// 获取当天12点的时间戳
function getCheckTime(hours: number) {
  let dt = new Date();
  dt.setHours(hours, 0, 0, 0);

  return dt.getTime();
}


export default activityHistory;
