/**
 * @flow
 * 类型判断
 */

function isType(s: any, typeString: string) {
  return {}.toString.call(s) === `[object ${typeString}]`;
}

export function isObject(s: any): boolean {
  return isType(s, "Object");
}

export function isArray(s: any): boolean {
  return Array.isArray ? Array.isArray(s) : isType(s, "Array");
}

export function isString(s: any): boolean {
  return isType(s, "String");
}

export function isFunction(s: any): boolean {
  return isType(s, "Function");
}

export function isNumber(s: any): boolean {
  return isType(s, "Number");
}

export function isRegExp(s: any): boolean {
  return isType(s, "RegExp");
}

export function isUndefined(s: any): boolean {
  return isType(s, "Undefined");
}

export function isNull(s: any): boolean {
  return isType(s, "Null");
}

export function isDate(s: any): boolean {
  return isType(s, "Date");
}

export function isEmptyObject(s: any): boolean {
  for (let name in s) {
    return false;
  }
  return true;
}

/**
 * 判断当前app版本是否支持某个功能 一般判定是否 >= 某个版本号，从而确认那个版本之后的才支持
 * @param currentVersion 当前app版本
 * @param supportVersion 某个功能支持的版本
 * @returns {boolean} 代表当前版本支持 false 代表不支持
 */
export function versionCompare(currentVersion: string, supportVersion: string) {
  if (!currentVersion) return false;

  let currentVersions = currentVersion.split(".");
  let supportVersions = supportVersion.split(".");

  const current_b_v = Number(currentVersions[0]);
  const current_m_v = Number(currentVersions[1]);
  const current_s_v = Number(currentVersions[2]);

  const support_b_v = Number(supportVersions[0]);
  const support_m_v = Number(supportVersions[1]);
  const support_s_v = Number(supportVersions[2]);

  if (current_b_v > support_b_v) {
    return true;
  }
  if (current_b_v >= support_b_v && current_m_v > support_m_v) {
    return true;
  }

  return (
    current_b_v >= support_b_v &&
    current_m_v >= support_m_v &&
    current_s_v >= support_s_v
  );
}
