import React from "react";
import style from "./index.module.scss";
import { Detector, Tools } from "@/utils";
import Config from "@/config/config";
import * as Sentry from '@sentry/browser';

const { domain } = Config;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: info
    })
    // You can also log error messages to an error reporting service here
    //logErrorToMyService(error, info);
    Sentry.captureException(error);
  }

  arouseApp = () => {
    const browserName = Detector.browser.name;
    if (browserName === "weixin" || browserName === "weibo") {
      window.location.href = `${domain}/`;
    } else {
      const refer = Tools.getUrlParam('refer') || '';
      // let appLink = Tools.zuzucheAppLink(browserName, refer);
      // window.open(`${appLink}url?link=${domain}/`);
      Tools.zuzucheAppOpenLink(browserName, domain, refer)
    }
  };

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <React.Fragment>
          <div className={style["main"]}>
            <p>{this.state.errorInfo}</p>
            <p>页面出现错误</p>
            <span onClick={() => this.arouseApp()}>返回租租车特权</span>
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}
