import Fetch from "./fetch";
// import Ajax from "./ajax";
import Tools from "./tools";
import Detector from "./detector";
import Storage from "./storage";
import * as Typeof from "./typeof";
import { isZzcLogin } from './nav';
import { getUrlParam, timestampToTime, nextIntegral, versionCompare } from './base';
import { getCookies } from './cookies';

export {
  Storage,
  Fetch,
  Detector,
  Tools,
  Typeof,
  isZzcLogin,
  getUrlParam,
  timestampToTime,
  nextIntegral,
  versionCompare,
  getCookies
};
