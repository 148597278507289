/*
 * @Author: fuyiwei
 * @Date: 2019-11-18 10:06:07
 * @Last Modified by: fuyiwei
 * @Last Modified time: 2020-02-18 15:59:44
 */
import { getCookies } from './cookies';
import Detector from '@/utils/detector';
/**
 * @description
 * @param {string} title
 * @param {string} content
 * @param {string} icon
 * @param url
 */
function share(title: string, content: string, icon: string, url: string): void {
    try {
        (window as any).zzc.call('appCustomRightBarList', {
            customItems: [
                {
                    type: 'share',
                    title: '分享',
                    shareTitle: title,
                    shareContent: content,
                    shareIcon: icon,
                    shareURL: url,
                    path: '',
                    sharePlatform: ['wxsession', 'wxtimeline', 'sina', 'qq', 'qqzone', 'copy'],
                },
            ],
            listener: function(response: any) {
                console.log(response, 'listener');
            },
            success: function(response: any) {
                console.log(response, 'success');
            },
        });
    } catch (error) {
        console.log(error);
    }
}

function foodShare(domain: string, titleText = '', contentText = '') {
    let img = new Image();
    img.src =
        'https://imgcdn5.zuzuche.com/m_tantu_image/20191115/6a6d2b5f5108d12122c9ffff432958a6.png';
    try {
        (window as any).zzc.call('appCustomRightBarList', {
            customItems: [
                {
                    type: 'foodHome',
                    title: '首页',
                    url: `${domain}/food?_=123`,
                    icon:
                        'https://imgcdn5.zuzuche.com/m_tantu_image/20191204/e3fa31c7b3f4c16f46dc50296ee90cdc.png',
                },
                {
                    id: 'foodOrder',
                    title: '我的订单',
                    url: `${domain}/food/order?_=123`,
                    isNeedLogin: true,
                    icon:
                        'https://imgcdn5.zuzuche.com/m_tantu_image/20191204/886f3ab379528acaff68e68cc73145d0.png',
                },
                {
                    type: 'share',
                    title: '分享',
                    shareTitle: titleText === '' ? '租租车会员特权' : titleText,
                    shareContent:
                        contentText === ''
                            ? '免费享加油洗车7折起，旅行出行优惠8折起，更有海量境外免税店折扣...'
                            : contentText,
                    shareIcon:
                        'https://imgcdn5.zuzuche.com/m_tantu_image/20191115/6a6d2b5f5108d12122c9ffff432958a6.png',
                    shareURL: window.location.href,
                    path: '',
                    sharePlatform: ['wxsession', 'wxtimeline', 'sina', 'qq', 'qqzone', 'copy'],
                },
            ],
            listener: function(response: any) {
                console.log(response, 'listener');
            },
            success: function(response: any) {
                console.log(response, 'success');
            },
        });
    } catch (error) {
        console.log(error);
    }
}

function rechargeShare(domain: string) {
    try {
        (window as any).zzc.call('appCustomRightBarList', {
            customItems: [
                {
                    type: 'home',
                    title: '首页',
                    url: `${window.location.protocol}${domain}/`,
                    icon:
                        'https://imgcdn5.zuzuche.com/m_tantu_image/20191204/e3fa31c7b3f4c16f46dc50296ee90cdc.png',
                },
                {
                    id: 'prepaidOrder',
                    title: '我的订单',
                    url: `${window.location.protocol}${domain}/recharge/orders`,
                    isNeedLogin: true,
                    icon:
                        'https://imgcdn5.zuzuche.com/m_tantu_image/20191204/886f3ab379528acaff68e68cc73145d0.png',
                },
                {
                    type: 'share',
                    title: '分享',
                    shareTitle: '快来租租车使用会员权益',
                    shareContent: '快来使用加油85折起，视频会员6折起，麦当劳肯德基等超值优惠',
                    shareIcon:
                        'https://imgcdn5.zuzuche.com/m_tantu_image/20191115/6a6d2b5f5108d12122c9ffff432958a6.png',
                    shareURL: `${window.location.protocol}${domain}/`,
                    path: '',
                    sharePlatform: ['wxsession', 'wxtimeline', 'sina', 'qq', 'qqzone', 'copy'],
                },
            ],
            listener: function(response: any) {
                console.log(response, 'listener');
            },
            success: function(response: any) {
                console.log(response, 'success');
            },
        });
    } catch (error) {
        console.log(error);
    }
}

//获取URL参数
function getUrlParam(key: string, url = document.location.href) {
    const reg = new RegExp('(#|&|\\?)' + key + '=([^&=?#]+)');
    const ret = url.match(reg);
    return (ret && ret[2]) || '';
}

function getCurrentPart(name: string, data: []) {
    let value: any;
    return filter(data, name);
    function filter(data: [], name: string) {
        if (!data) return null;
        for (let i = 0; i < data.length; i++) {
            if ((data[i] as any).oil_name === name) {
                value = (data[i] as any).gun_nos;
                break;
            }
        }
        return value;
    }
}

interface Params {
    hk: string;
    en: string;
    tw: string;
}

function i18n(params: Params): string {
    const lang = getCookies('lang');
    let response: string = '';
    if (lang === 'hk') {
        response = params.hk;
    } else if (lang === 'tw') {
        response = params.tw;
    } else {
        response = params.en;
    }
    return response;
}

const getSecondsFromExpiry = (expiry: number) => {
    const now = new Date().getTime();
    const milliSecondsDistance = expiry - now;
    if (milliSecondsDistance > 0) {
        return Math.floor(milliSecondsDistance / 1000);
    }
    return 0;
};

const expiryTimestamp = (expiryTimestamp: number) => {
    const isValid = new Date(expiryTimestamp).getTime() > 0;
    if (!isValid) {
        console.warn('Invalid expiryTimestamp settings', expiryTimestamp);
    }
    return isValid;
};

const onExpire = (onExpire: any) => {
    const isValid = onExpire && typeof onExpire === 'function';
    if (onExpire && !isValid) {
        console.warn('Invalid onExpire settings function', onExpire);
    }
    return isValid;
};

const getTimeFromSeconds = (totalSeconds: number) => {
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return {
        seconds,
        minutes,
        hours,
        days,
    };
};

const isZuzuche = () => {
    const browserName = (Detector as any).browser.name;
    if (browserName === 'zuzuche' || browserName === 'zuzucherewards') {
        return true;
    } else {
        return false;
    }
};

const isZuzucheRewards = () => {
    const browserName = (Detector as any).browser.name;
    if (browserName === 'zuzucherewards') {
        return true;
    } else {
        return false;
    }
};

const zuzucheAppOpenLink = (browserName: string, domain: string, refer?: string) => {
    const link = zuzucheAppLink(browserName, refer);
    console.log(link, 'link');
    if (browserName === 'zuzuche') {
        window.open(`${link}url?link=${domain}/`);
    } else if (browserName === 'zuzucherewards') {
        window.open(`${link}url?link=${domain}/`);
    }
};

const zuzucheAppLink = (browserName: string, refer?: string) => {
    let appLink = '';
    if (browserName === 'zuzuche' || browserName === 'zuzucherewards') {
        if (browserName === 'zuzuche') {
            appLink = 'zuzuche://';
        } else if (browserName === 'zuzucherewards') {
            appLink = 'zzcrewards://';
        }
    } else {
        if (refer === 'zzc_rewards') {
            appLink = 'zzcrewards://';
        } else {
            appLink = 'zuzuche://';
        }
    }
    console.log(appLink, 'appLink');
    return appLink;
};

const dateFormat = (fmt: any, date: any) => {
    let ret;
    const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
            fmt = fmt.replace(
                ret[1],
                ret[1].length == 1 ? (opt as any)[k] : (opt as any)[k].padStart(ret[1].length, '0')
            );
        }
    }
    return fmt;
};

// 2021-4-30 判断版本号是否大于等于指定版本
function versionLg(version:string, curVersion:string){
    let arrVersion = version.split('.');
    let arrCurVersion = curVersion.split('.');

    for (let i = 0, len = arrCurVersion.length; i < len; i++) {
        if(arrCurVersion[i]<arrVersion[i]){
            return false
        }
    }
    return true;
}

// 判断版本号是否等于指定版本
function versionLgEqual(version:string, curVersion:string) {
    let arrVersion = version.split('.');
    let arrCurVersion = curVersion.split('.');

    for (let i = 0, len = arrCurVersion.length; i < len; i++) {
        if(arrCurVersion[i] === arrVersion[i]){
            return true
        }
    }
    return false;
}


export default {
    zuzucheAppLink,
    zuzucheAppOpenLink,
    isZuzuche,
    getTimeFromSeconds,
    share,
    onExpire,
    i18n,
    rechargeShare,
    isZuzucheRewards,
    dateFormat,
    expiryTimestamp,
    foodShare,
    getCurrentPart,
    getUrlParam,
    getSecondsFromExpiry,
    versionLg,
    versionLgEqual
};
