import { Fetch } from "@/utils";

const getOilInfo = (params: any) =>
  Fetch(`/station/getOilInfo?id=${params.id}`, { method: "GET" });

const onSubmit = (params: any) =>
  Fetch(`/station/payUrl?id=${params.id}&gun_no=${params.gunNo}`, { method: "GET" });

export default {
  getOilInfo,
  onSubmit
};
