/*
 * @Author: fuyiwei
 * @Date: 2019-11-18 10:06:27
 * @Last Modified by: fuyiwei
 * @Last Modified time: 2020-01-03 14:38:43
 */

import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '@/config/config';
import { Toast } from 'antd-mobile';
import { isZzcLogin, Storage } from '@/utils';

const isLocal = window.location.host.indexOf('localhost') > -1;
const showStatus = (status: number) => {
  let message = '';
  switch (status) {
    case 400:
      message = '请求错误';
      break;
    case 401:
      message = '未授权，请重新登录';
      break;
    case 403:
      message = '拒绝访问';
      break;
    case 404:
      message = '请求出错';
      break;
    case 408:
      message = '请求超时';
      break;
    case 500:
      message = '服务器错误';
      break;
    case 501:
      message = '服务未实现';
      break;
    case 502:
      message = '网络错误';
      break;
    case 503:
      message = '服务不可用';
      break;
    case 504:
      message = '网络超时';
      break;
    case 505:
      message = 'HTTP版本不受支持';
      break;
    default:
      message = `连接出错(${status})!`;
  }
  return `${message}，请检查网络或联系管理员！`;
};

// 创建axios实例
const service = (axios as any).create({
  baseURL: `${config.api}`,
  withCredentials: true,
  timeout: 30000,
  transformRequest: [
    (data: any) => {
      // Toast.loading('加载中');
      console.log('数据加载中');
      data = JSON.stringify(data);
      return data;
    },
  ],
  validateStatus() {
    // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
    return true;
  },
  transformResponse: [
    (data: any) => {
      // Toast.hide();
      console.log('数据加载完成');
      if (typeof data === 'string' && data.startsWith('{')) {
        data = JSON.parse(data);
      }
      return data;
    },
  ],
});

axiosRetry(service, {
  retries: 3, //设置自动发送请求次数
  shouldResetTimeout: true,
  retryDelay: retryCount => {
    return retryCount * 1000;
  },
  retryCondition: (error: any) => {
    console.log('发起请求重试');
    return error.config.method === 'get' || error.config.method === 'post';
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config: any) => {
    // 增加全局token参数
    if (!isLocal) {
      const loginToken = Storage.get('loginToken');
      if (loginToken && loginToken.token && loginToken.addTimeStamp && Date.now() - loginToken.addTimeStamp < 20 * 60 * 1000) {
        config.headers.Authorization = loginToken.token;
      }
    }

    return config;
  },
  (error: any) => {
    // 错误抛到业务代码
    error.data = {};
    error.data.msg = '服务器异常，请联系管理员！';
    console.log('服务器异常，请联系管理员！');
    return Promise.resolve(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: any) => {
    const status = response.status;
    const res = response.data;
    let msg = '';
    if (status < 200 || status >= 300) {
      // 处理http错误，抛到业务代码
      msg = showStatus(status);
      if (typeof response.data === 'string') {
        response.data = { msg };
      } else {
        response.data.msg = msg;
      }
      console.log(`请求错误 ${response.data.msg}`);
      Toast.offline(`请求错误 ${response.data.msg}`, 3);
    } else {
      try {

        console.log(res.code, 12);
        if (res.code === 1) {
          return response.data;
        } else if (res.code === 120200001) {
          // 针对token接口单独处理 不走验证
          // const isTokenApi = response.config.url.indexOf('/api/user/profile/token') > -1;
          // if (isTokenApi) return response.data;

          Toast.offline('请登陆后再操作', 3, () => {

            console.log(1231);
            isZzcLogin({ ref_url: window.location.href });
          });
          return false;
        } else if (res.code === 120200030) {
          const data = {
            code: 120200030,
            data: '',
            msg: res.msg || ''
          };
          return data;
        } else if (res.code === 120200010) {
          const data = {
            code: 120200010,
            data: '',
            msg: ''
          };
          return data;
        } else {
          console.log('请求超时或服务器异常，请检查网络或联系管理员！');
          Toast.offline(res.msg, 3);
        }
      } catch (error) {
        console.log('error==>', error);
      }
    }
    return response.data;
  },
  (error: any) => {
    // 错误抛到业务代码
    error.data = {};
    error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！';
    console.log('请求超时或服务器异常，请检查网络或联系管理员！');
    Toast.offline(error.data.msg, 3);
    return Promise.resolve(error);
  }
);

export default service;