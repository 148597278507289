import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Routers from './router/index';
import { Provider } from 'react-redux';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';
import store from './store/index';
import './index.css';
import initWeChat, { wxShare } from '@/utils/wechat';
import Config from '@/config/config';
import * as Sentry from '@sentry/browser';
import { checkTokenLogin } from '@/utils/auth';

let isLocal = process.env.REACT_APP_PATH_CONFIG === 'dev';
if (!isLocal) {
    Sentry.init({ dsn: 'https://8fc9f0358e56466bb6e902c942aac2bf@logger2.zuzuche.com/41' });
}

// 特权项目的分享图片优先加载
let img = new Image();
img.src = Config.shareIcon;

// 增加全局token
initWeChat()
    .then(() => {
        checkTokenLogin()
            .then(res => {
                initApp();
            })
            .catch(err => {});

        // wxShare({
        //     title: Config.shareTitle, // 分享标题
        //     desc: Config.shareContent,
        //     link: `${Config.domain}/`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        //     imgUrl: Config.shareIcon, // 分享图标
        //     success: function() {
        //         // 设置成功
        //     },
        // });
    })
    .catch(() => {
        checkTokenLogin()
            .then(res => {
                initApp();
            })
            .catch(err => {});
    });

function initApp() {
    ReactDOM.render(
        <Provider store={store}>
            <Suspense fallback={<Loading />}>
                <ErrorBoundary>
                    <Routers />
                </ErrorBoundary>
            </Suspense>
        </Provider>,
        document.getElementById('root')
    );

    serviceWorker.unregister();
}
