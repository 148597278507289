const meituan = {
  state: {
    appkey: '489a22c973757252242a0ff2529f5704849',
    scheme: 'imeituan://www.meituan.com/hotel/homepage?lch=cps:x:0',
    h5: 'https://runion.meituan.com/url?a=1',
    url: 'https://i.meituan.com/awp/h5/hotel/search/search.html?cevent=imt%2Fhomepage%2Fcategory1%2F20%26ZZCAppArg%3dwholepageselfopen&sid='
  },
  reducers: {
    
  },
  effects: (dispatch: any) => {
    return {
     
    }
  }
};

export default meituan;
