import {Fetch, isZzcLogin} from "@/utils";
import { Toast } from "antd-mobile";
type typeOptions = {
  [key: number]: any
}
const typeMap: typeOptions = {
  1: {
    reg: '^1(3|4|5|6|7|8|9)[0-9]{9}$',
    type: 'tel',
    pattern: '[0-9]*'
  }, // 手机号
  2: {
    reg: '^[0-9]{5,12}$',
    type: 'number',
    pattern: '[0-9]*'
  }, // qq号
  3: {
    reg: '',
    type: 'text',
    pattern: ''
  } // 用户名
};

const recharge = {
  state: {
    loading: true,
    noSku: false,
    banners: [],
    sku_types: [],
    sku_list: [],
    goods: {
      input_type: 3,
      input_tips: '',
      matchReg: { ...typeMap[3] }
    },
    showSkuList: [],
    selectSku: {},
    confirmPrepaid: false,
  },
  reducers: {
    set(state: any, data: any) {
      return {
        ...state,
        ...data,
        loading: false
      }
    },
    setConfirmPrepaid(state: any, confirmPrepaid: boolean) {
      return {
        ...state,
        confirmPrepaid
      }
    },
    setSelectType(state: any, typeId: any) {
      // 同样判断- 也许应该放在业务层做
      if (typeId === state.selectSku.typeId) {
        return state
      }

      let showSkuList: any = [];
      let selectSku: any;
      state.sku_list.forEach((sku: any) => {
        if (sku.type_id == typeId) {
          showSkuList.push(sku);
          if (!selectSku && !sku.is_sold_out) { selectSku = sku; }
        }
      });
      return {
        ...state,
        selectSku: selectSku || { type_id: typeId },
        showSkuList,
      }
    },
    setSelectSku(state: any, skuId: any) {
      if (skuId === state.selectSku.sku_id) {
        return state
      }

      const { showSkuList, selectSku } = resolveSelect(state, skuId);
      return {
        ...state,
        showSkuList,
        selectSku
      }
    },
  },
  effects: (dispatch: any) => {
    return {
      async init(skuId: any) {
        // 错误页面
        if (skuId === '') {
          dispatch.recharge.set({ noSku: true });
          return;
        }

        const { data, code } = await Fetch(`/recharge/goods`, {
          params: { sku_id: skuId }
        });
        if (code == 1 && data && data.sku_list) {
          const { showSkuList, selectSku } = resolveSelect(data, skuId);
          data.banners = data.banners.map((banner: any) => {
            return banner.banner_img;
          });

          data.goods.matchReg = typeMap[data.goods.input_type];

          try {
            dispatch.recharge.set({
              ...data,
              showSkuList,
              selectSku
            });
          } catch (err) {
            dispatch.recharge.set({ noSku: true });
          }
        } else {
          dispatch.recharge.set({ noSku: true });
        }

      },
      async submit(payload: any) {
        Toast.info('订单提交中...');
        const { data, code, msg } = await Fetch(`/recharge/order`, {
          method: "POST",
          params: {
            sku_id: payload.skuId,
            account: payload.account,
          }
        });
        if (code == 1 && data && data.url) {
          window.location.href = `${data.url}&ZZCAppArg=SelfOpenPage`;
        } else {
          Toast.info(msg || '该商品太火爆了，请看看别的吧', 2);
        }
        return data;
      }
    }
  }
};

// 获取选中的sku 及对应sku的同类型商品列表
function resolveSelect(data: any, skuId: any) {
  let showSkuList: any = [];
  let selectSku: any;
  let typeId: any;
  data.sku_list.forEach((sku: any) => {
    if (sku.sku_id == skuId) {
      // 如果该sku售罄，选择该sku的type的后一个sku
      if (!sku.is_sold_out) {
        selectSku = sku;
      }
      typeId = sku.type_id;
    }
  });

  data.sku_list.forEach((sku: any) => {
    if (sku.type_id == typeId) {
      showSkuList.push(sku);
      //Sku下架了选中当前SPU默认的后一个
      if (!selectSku && !sku.is_sold_out) { selectSku = sku; }
    }
  });

  return { showSkuList, selectSku: selectSku ? selectSku : { type_id: typeId } };
}

export default recharge;
