export function getUrlParam (key: string, url = window.location.href) {
  var reg = new RegExp("(#|&|\\?)" + key + "=([^&=\\?#]+)");
  var ret = url.match(reg);
  return (ret && decodeURIComponent(ret[2])) || '';
};


export function timestampToTime(timestamp: number) {
  var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '/';
  var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
  var D = date.getDate() + ' ';
  var h = date.getHours() + ':';
  var m = date.getMinutes() + ':';
  var s = date.getSeconds();
  return Y+M+D+h+m+s;
}

export function nextIntegral(newTime: number) {
  const date = new Date();

  const newDate = Date.parse((date as any));
  const currTime = newTime || parseInt((newDate/1000) as any);
  const newDateSring = timestampToTime(currTime);
  let nextDate = '';
  // ios 2020/03/20 24:0:0不兼容 要转换成 2020/03/21 0:0:0
  if (date.getHours()+1 == 24) {
    const iosNewDateSring = timestampToTime(parseInt((newDate/1000) as any) + 24 * 60 * 60);
    nextDate = `${iosNewDateSring.split(' ')[0]} 0:0:0`
  } else {
    nextDate = `${newDateSring.split(' ')[0]} ${date.getHours() >= 24 ? 0 : date.getHours()+1}:0:0`;
  }
  return new Date(nextDate)
}

/**
 * 判断当前app版本是否支持某个功能 一般判定是否 >= 某个版本号，从而确认那个版本之后的才支持
 * @param currentVersion 当前app版本
 * @param supportVersion 某个功能支持的版本
 * @returns {boolean} 代表当前版本支持 false 代表不支持
 */
export function versionCompare(currentVersion: string, supportVersion: string){
  if (!currentVersion) return false;

  let currentVersions = currentVersion.split('.');
  let supportVersions = supportVersion.split('.');

  const current_b_v = Number(currentVersions[0]);
  const current_m_v = Number(currentVersions[1]);
  const current_s_v = Number(currentVersions[2]);

  const support_b_v = Number(supportVersions[0]);
  const support_m_v = Number(supportVersions[1]);
  const support_s_v = Number(supportVersions[2]);

  if(current_b_v>support_b_v){
    return true;
  }
  if(current_b_v>=support_b_v && current_m_v>support_m_v){
    return true;
  }

  return current_b_v >= support_b_v && current_m_v >= support_m_v &&
    current_s_v >= support_s_v;
}


